import {
	endOfDay,
	formatISO,
	parseISO,
	startOfDay,
} from 'date-fns';
import React from 'react';

import CK from '~/types/contentking';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageTimeDiscoveredAt from '~/hooks/usePageTimeDiscoveredAt';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWriteQuerystring from '~/hooks/useWriteQuerystring';

import {
	isString,
} from '~/utilities/typeCheck';



export enum TrackedChangesProperty {
	H1Heading = 'h1_heading',
	H2Heading = 'h2_heading',
	H3Heading = 'h3_heading',
	H4Heading = 'h4_heading',
	H5Heading = 'h5_heading',
	H6Heading = 'h6_heading',
	PageDiscovered = 'page_discovered',
	UnreliableResponse = 'unreliable_response',
}



type Property = {
	changeTypes: Array<string>,
	defaultValue: boolean,
	isSearchable: boolean,
};

const PROPERTIES_MAPPING: Record<string, Property> = {
	[CK.PagesCommonColumn.AnalyticsServices]: {
		changeTypes: [
			'adobe_analytics',
			'google_analytics',
		],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.CanonicalUrl]: {
		changeTypes: ['canonical', 'link_canonical_header'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.H1Heading]: {
		changeTypes: ['h1'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.H2Heading]: {
		changeTypes: ['h2'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.H3Heading]: {
		changeTypes: ['h3'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.H4Heading]: {
		changeTypes: ['h4'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.H5Heading]: {
		changeTypes: ['h5'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.H6Heading]: {
		changeTypes: ['h6'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.HreflangLanguage]: {
		changeTypes: ['link_alternate_hreflang', 'link_alternate_hreflang_sitemap'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.IsDisallowedInRobotsTxt]: {
		changeTypes: ['is_disallowed_in_robots_txt'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.IsInSitemap]: {
		changeTypes: ['is_in_sitemap'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.IsIndexableDueToMetaRobots]: {
		changeTypes: ['meta_robots', 'meta_googlebot', 'meta_bingbot', 'meta_slurp', 'meta_yandex'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.IsIndexableDueToXRobotsTag]: {
		changeTypes: ['x_robots_tag'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.IsIndexable]: {
		changeTypes: ['is_indexable'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthouseCumulativeLayoutShift]: {
		changeTypes: ['lighthouse_cls_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthouseFirstContentfulPaint]: {
		changeTypes: ['lighthouse_fcp_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthouseLargestContentfulPaint]: {
		changeTypes: ['lighthouse_lcp_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthousePerformance]: {
		changeTypes: ['lighthouse_performance_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthouseSpeedIndex]: {
		changeTypes: ['lighthouse_si_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthouseTimeToInteractive]: {
		changeTypes: ['lighthouse_tti_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LighthouseTotalBlockingTime]: {
		changeTypes: ['lighthouse_tbt_range'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.LinkAmp]: {
		changeTypes: ['link_amp'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.LinkNext]: {
		changeTypes: ['link_next'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.LinkPrev]: {
		changeTypes: ['link_prev'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.MetaDescription]: {
		changeTypes: ['meta_description'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.MobileVariant]: {
		changeTypes: ['mobile_variant'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.OpenGraphDescription]: {
		changeTypes: ['open_graph_description'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.OpenGraphImage]: {
		changeTypes: ['open_graph_image'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.OpenGraphTitle]: {
		changeTypes: ['open_graph_title'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.OpenGraphType]: {
		changeTypes: ['open_graph_type'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.OpenGraphUrl]: {
		changeTypes: ['open_graph_url'],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.PageDiscovered]: {
		changeTypes: ['page_discovered'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.Redirect]: {
		changeTypes: ['redirect'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.SchemaOrgTypes]: {
		changeTypes: ['schema_org_types'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.TagManagers]: {
		changeTypes: [
			'adobe_tag_manager',
			'google_tag_manager',
			'segment_com_tag_manager',
		],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.Title]: {
		changeTypes: ['title'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.TwitterCard]: {
		changeTypes: ['twitter_card'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.TwitterDescription]: {
		changeTypes: ['twitter_description'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.TwitterImage]: {
		changeTypes: ['twitter_image'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.TwitterSite]: {
		changeTypes: ['twitter_site'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.TwitterTitle]: {
		changeTypes: ['twitter_title'],
		defaultValue: true,
		isSearchable: true,
	},
	[CK.PagesCommonColumn.Type]: {
		changeTypes: ['type', 'status_code'],
		defaultValue: true,
		isSearchable: false,
	},
	[CK.PagesCommonColumn.VisualAnalyticsServices]: {
		changeTypes: [
			'clicktale',
			'clicky',
			'contentsquare',
			'crazy_egg',
			'hotjar',
			'inspectlet',
			'microsoft_clarity',
			'mouseflow',
			'smartlook',
		],
		defaultValue: true,
		isSearchable: true,
	},
	[TrackedChangesProperty.UnreliableResponse]: {
		changeTypes: ['last_unreliable_response'],
		defaultValue: false,
		isSearchable: false,
	},
};

function encodeDate(date?: Date | null): string | null {
	if (!date) {
		return null;
	}

	return formatISO(date, { representation: 'date' });
}

function encodeSearchTerm(searchTerm?: string | null): string | null {
	if (!searchTerm || searchTerm.trim().length === 0) {
		return null;
	}

	return searchTerm.trim();
}



type DateRange = {
	start: Date | null,
	end: Date | null,
};

export type HistoryFilter = {
	changeTypes: Array<string>,
	dateRange: DateRange,
	properties: Array<string>,
	searchTerm: string | null,
	setDateRange: (dateRange: DateRange) => void,
	setProperties: (properties: Array<string>) => void,
	setSearchTerm: (searchTerm: string | null) => void,
	unsetEndDate: () => void,
	unsetStartDate: () => void,
};



function useHistoryFilter(): HistoryFilter {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const pageTimeDiscoveredAt = usePageTimeDiscoveredAt(legacyUrlId, websiteId);
	const urlState = useUrlState();

	const {
		name: routeName,
		params: routeParams,
	} = urlState;

	const supportedProperties = React.useMemo(
		() => {
			const supportedProperties = {
				...PROPERTIES_MAPPING,
			};

			customElementDefinitions.listAll().forEach((customElementDefinition) => {
				supportedProperties[customElementDefinition.column] = {
					changeTypes: [customElementDefinition.column],
					defaultValue: true,
					isSearchable: true,
				};
			});

			enrichmentFieldDefinitions.listAll().forEach((enrichmentField) => {
				supportedProperties[enrichmentField.column] = {
					changeTypes: [enrichmentField.column, 'ef_' + enrichmentField.id],
					defaultValue: true,
					isSearchable: true,
				};
			});

			return supportedProperties;
		},
		[
			customElementDefinitions,
			enrichmentFieldDefinitions,
		],
	);

	const isHistoryScreen = routeName === 'website.pages.detail.history';

	const encodeProperties = React.useCallback(
		(properties?: Array<string> | null): string | null => {
			if (!properties || properties.length === 0) {
				return null;
			}

			if (properties.length === Object.keys(supportedProperties).length) {
				return 'all';
			}

			const allDefaultValues = Object.keys(supportedProperties).every((name) => {
				return supportedProperties[name]?.defaultValue === properties.includes(name);
			});

			if (allDefaultValues) {
				return null;
			}

			return properties.join('~');
		},
		[
			supportedProperties,
		],
	);

	function decodeDate(value?: string): Date | null {
		if (!value) {
			return null;
		}

		return parseISO(value);
	}

	function decodeProperties(value?: string): Array<string> | null {
		if (!value) {
			return Object.keys(supportedProperties).filter((name) => {
				return supportedProperties[name]?.defaultValue === true;
			});
		}

		if (value === 'all') {
			return Object.keys(supportedProperties);
		}

		return value.split('~');
	}

	function decodeSearchTerm(value?: string): string | null {
		if (!value || value.trim().length === 0) {
			return null;
		}

		return value.trim();
	}

	const [dateRange, setDateRange] = React.useState<{
		end: Date | null,
		start: Date | null,
	}>({
		end: decodeDate(routeParams['end-date']),
		start: decodeDate(routeParams['start-date']),
	});

	const [properties, setProperties] = React.useState<Array<string> | null>(
		decodeProperties(routeParams['properties']),
	);

	const [searchTerm, setSearchTerm] = React.useState<string | null>(
		decodeSearchTerm(routeParams['search']),
	);

	useWriteQuerystring('start-date', isHistoryScreen ? dateRange.start : null, encodeDate);
	useWriteQuerystring('end-date', isHistoryScreen ? dateRange.end : null, encodeDate);
	useWriteQuerystring('properties', isHistoryScreen ? properties : null, encodeProperties);
	useWriteQuerystring('search', isHistoryScreen ? searchTerm : null, encodeSearchTerm);

	const unsetStartDate = React.useCallback(
		() => {
			setDateRange((dateRange) => ({
				end: dateRange.end,
				start: pageTimeDiscoveredAt ?? dateRange.start,
			}));
		},
		[
			pageTimeDiscoveredAt,
		],
	);

	const unsetEndDate = React.useCallback(
		() => {
			setDateRange((dateRange) => ({
				end: new Date(),
				start: dateRange.start,
			}));
		},
		[],
	);

	return React.useMemo(
		() => {
			const defaultDateRange = {
				end: new Date(),
				start: pageTimeDiscoveredAt ?? new Date(),
			};

			const activeSearchTerm = isString(searchTerm) && searchTerm.trim() !== '' ? searchTerm : null;
			const activeProperties = properties ?? Object.keys(supportedProperties);

			const changeTypes = activeProperties.reduce((changeTypes, name) => {
				const property = supportedProperties[name];

				if (property) {
					if (activeSearchTerm && !property.isSearchable) {
						return changeTypes;
					}

					return [...changeTypes, ...property.changeTypes];
				}

				return changeTypes;
			}, []);

			return {
				changeTypes,
				dateRange: {
					start: startOfDay(dateRange.start ?? defaultDateRange.start),
					end: endOfDay(dateRange.end ?? defaultDateRange.end),
				},
				properties: activeProperties,
				searchTerm: activeSearchTerm,
				setDateRange,
				setProperties,
				setSearchTerm,
				unsetEndDate,
				unsetStartDate,
			};
		},
		[
			dateRange,
			pageTimeDiscoveredAt,
			properties,
			searchTerm,
			setDateRange,
			setProperties,
			setSearchTerm,
			supportedProperties,
			unsetEndDate,
			unsetStartDate,
		],
	);
}

export default useHistoryFilter;
