import React from 'react';

import {
	ScopeSectionContext,
} from '~/components/app/Dashboard/ScopeSection';


import {
	MissingContextProviderError,
} from '~/errors';



function useDashboardScopeSection() {
	const context = React.useContext(ScopeSectionContext);

	if (context === null) {
		throw new MissingContextProviderError('ScopeSectionContext');
	}

	return context;
}



export default useDashboardScopeSection;
