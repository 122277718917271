import React from 'react';

import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountType from '~/hooks/useAccountType';
import useCurrentUserId from '~/hooks/useCurrentUserId';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useRoyalMode from '~/hooks/useRoyalMode';
import useUserEmail from '~/hooks/useUserEmail';
import useUserLocale from '~/hooks/useUserLocale';
import useUserRole from '~/hooks/useUserRole';

import {
	CONTENTKING_ENVIRONMENT,
} from '~/config';

import {
	loadPendo,
} from '~/globals';

import {
	LOCALE_EN_US,
} from '~/model/locales';
import {
	type PendoGuide,
	getPendoGuideName,
} from '~/model/pendoGuides';

import {
	assert,
} from '~/utilities/typeCheck';



const pendoPromise = loadPendo();
let pendoInstance: pendo.Pendo | null = null;



type PendoContextValue = {
	showGuide: (guideName: string) => Promise<void>,
	trackEvent: (message: string, payload: Record<string, any>) => void,
};

export const PendoContext = React.createContext<PendoContextValue>({
	showGuide: () => {
		return Promise.reject(new Error('Pendo disabled'));
	},
	trackEvent: () => {
		return Promise.reject(new Error('Pendo disabled'));
	},
});



type Props = {
	children?: React.ReactNode,
};

const PendoProvider: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	const currentUserId = useCurrentUserId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();

	const currentUserEmail = useUserEmail(currentUserId);
	const currentUserRole = useUserRole(effectiveHomeAccountId, currentUserId);
	const currentUserLocale = useUserLocale(currentUserId);
	const isImpersonated = useRoyalMode().isImpersonated;

	const homeAccountPhase = useAccountPhase(effectiveHomeAccountId);
	const homeAccountType = useAccountType(effectiveHomeAccountId);

	const pendoStatus = React.useRef<'pending' | 'initialized' | 'aborted'>('pending');

	React.useEffect(
		() => {
			if (
				currentUserEmail === null
				|| currentUserRole === null
				|| effectiveHomeAccountId === null
				|| homeAccountPhase === null
				|| homeAccountType === null
			) {
				return;
			}

			if (isImpersonated === true) {
				pendoStatus.current = 'aborted';
				return;
			}

			if (pendoStatus.current !== 'pending') {
				return;
			}

			pendoPromise.then((pendo) => {
				pendoInstance = pendo;
				pendo.initialize({
					visitor: {
						id: currentUserEmail,
						app_language: 'en-US',
						environment: CONTENTKING_ENVIRONMENT,
						role: currentUserRole,
					},
					account: {
						id: effectiveHomeAccountId.toString(),
						environment: CONTENTKING_ENVIRONMENT,
						phase: homeAccountPhase,
						type: homeAccountType,
					},
				});

				pendoStatus.current = 'initialized';
			});
		},
		[
			currentUserEmail,
			currentUserRole,
			effectiveHomeAccountId,
			homeAccountPhase,
			homeAccountType,
			isImpersonated,
		],
	);

	const showGuide = React.useCallback(
		(guideName: PendoGuide) => {
			return new Promise<void>(async (resolve, reject) => {
				if (pendoStatus.current === 'aborted') {
					reject(new Error('Pendo disabled'));
				}

				if (pendoStatus.current === 'pending') {
					await pendoPromise;
				}

				assert(pendoInstance !== null, 'Pendo not initialized');

				await pendoInstance.loadGuides();

				const locale = currentUserLocale ?? LOCALE_EN_US;
				const guideNameForLocale = getPendoGuideName(guideName, locale);

				if (guideNameForLocale === null) {
					return reject(`Guide "${guideName}" not found for locale "${locale}"`);
				}

				const guide = pendo.findGuideByName(guideNameForLocale);

				if (guide && !guide.isComplete()) {
					guide.show();
				}
			});
		},
		[
			currentUserLocale,
		],
	);

	const trackEvent = React.useCallback(
		(message: string, payload: Record<string, any>) => {
			return new Promise<void>(async (resolve, reject) => {
				if (pendoStatus.current === 'aborted') {
					reject(new Error('Pendo disabled'));
				}

				if (pendoStatus.current === 'pending') {
					await pendoPromise;
				}

				assert(pendoInstance !== null, 'Pendo not initialized');

				pendoInstance.track(message, payload);
			});
		},
		[],
	);

	const context = React.useMemo(
		() => {
			return {
				showGuide,
				trackEvent,
			};
		},
		[
			showGuide,
			trackEvent,
		],
	);

	return (
		<PendoContext.Provider value={context}>
			{children}
		</PendoContext.Provider>
	);
};



export default PendoProvider;
