/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RetryFailedJobsMutationVariables = GraphQL.Exact<{
  jobQueue: GraphQL.Scalars['String']['input'];
  resetProgress: GraphQL.Scalars['Boolean']['input'];
}>;


export type RetryFailedJobsMutation = { readonly __typename?: 'Mutation', readonly RetryFailedJobs: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const RetryFailedJobsDocument = gql`
    mutation RetryFailedJobs($jobQueue: String!, $resetProgress: Boolean!) {
  RetryFailedJobs(jobQueue: $jobQueue, resetProgress: $resetProgress) {
    query {
      ping
    }
  }
}
    `;
export type RetryFailedJobsMutationFn = Apollo.MutationFunction<RetryFailedJobsMutation, RetryFailedJobsMutationVariables>;

/**
 * __useRetryFailedJobsMutation__
 *
 * To run a mutation, you first call `useRetryFailedJobsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryFailedJobsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryFailedJobsMutation, { data, loading, error }] = useRetryFailedJobsMutation({
 *   variables: {
 *      jobQueue: // value for 'jobQueue'
 *      resetProgress: // value for 'resetProgress'
 *   },
 * });
 */
export function useRetryFailedJobsMutation(baseOptions?: Apollo.MutationHookOptions<RetryFailedJobsMutation, RetryFailedJobsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetryFailedJobsMutation, RetryFailedJobsMutationVariables>(RetryFailedJobsDocument, options);
      }
export type RetryFailedJobsMutationHookResult = ReturnType<typeof useRetryFailedJobsMutation>;
export type RetryFailedJobsMutationResult = Apollo.MutationResult<RetryFailedJobsMutation>;
export type RetryFailedJobsMutationOptions = Apollo.BaseMutationOptions<RetryFailedJobsMutation, RetryFailedJobsMutationVariables>;