import React from 'react';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import TextArea from '~/components/atoms/forms/components/TextArea';

import useFormContext from '~/hooks/useFormContext';

import {
	UserAgent,
} from '~/model/websites/userAgents';



type Props = {
	changedSettings: ReadonlyArray<string>,
	userAgent: UserAgent,
};


const WebsiteAdminTestFetchViaRenderingUserAgentFieldRow: React.FC<Props> = (props) => {
	const {
		changedSettings,
		userAgent,
	} = props;

	const formContext = useFormContext();

	const isEditable = changedSettings.includes('userAgent');
	const selectedUserAgent = (formContext.values['userAgent'] ?? userAgent ?? null) as (UserAgent | null);

	if (isEditable && selectedUserAgent === UserAgent.Custom) {
		return (
			<FormRow
				htmlFor="userAgentCustomValue"
				label="Custom User Agent"
			>
				<FieldStatus
					name="userAgentCustomValue"
				>
					<TextArea
						isDisabled={false}
						name="userAgentCustomValue"
						resizable={true}
					/>
				</FieldStatus>
			</FormRow>
		);
	}
};



export default WebsiteAdminTestFetchViaRenderingUserAgentFieldRow;

