import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Immutable from 'immutable';
import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';

import {
	getSharedBarChartStyles,
} from '~/utilities/highcharts';



const BAR_HEIGHT = 30;



class StackedBarChart extends Component {

	constructor(props, context) {
		super(props, context);

		this.chartRef = React.createRef();
	}



	_addBackgroundForEveryBar(arrayData, categories, maximum) {
		// we can create background by adding fake bar from 0 to maximum (bellow other stacked bars)
		arrayData.unshift({
			enableMouseTracking: false,
			showInLegend: false,
			grouping: false,
			color: 'rgba(0,0,0,0.05)',
			data: Array.from({ length: categories.length }, () => maximum),
		});

		return arrayData;
	}



	_applyOpacityToLineColor(data) {
		return data.map((seriesData) => {
			if (seriesData.color) {
				seriesData.color = new Highcharts.Color(seriesData.color).setOpacity(0.8).get();
			}

			return seriesData;
		});
	}



	shouldComponentUpdate() {
		return false;
	}



	UNSAFE_componentWillReceiveProps({ categories: nextCategories, data: nextData, maximum: nextMaximum, showXAxis: nextShowXAxis }) {
		const {
			data,
		} = this.props;

		if (nextData && nextData.size > 0 && !Immutable.is(data, nextData)) {
			const chart = this.chartRef.current && this.chartRef.current.chart;

			if (chart && chart.series) {
				let arrayData = nextData.toArray();
				arrayData = this._applyOpacityToLineColor(arrayData);
				arrayData = this._addBackgroundForEveryBar(arrayData, nextCategories, nextMaximum);

				arrayData.forEach((seriesData, seriesIndex) => {
					chart.series[seriesIndex].setData(
						seriesData.data,
						false,
						false,
						true,
					);
				});

				let chartHeight = BAR_HEIGHT * nextCategories.length;

				if (nextShowXAxis) {
					chartHeight += 25;
				}

				chart.setSize(undefined, chartHeight);

				chart.xAxis[0].setCategories(nextCategories);

				chart.redraw();
			}
		}
	}



	render() {
		const {
			categories,
			data,
			maximum,
			showXAxis,
			tooltipRenderer,
			xAxisLabelRenderer,
		} = this.props;

		let arrayData = data.toArray();
		arrayData = this._applyOpacityToLineColor(arrayData);
		arrayData = this._addBackgroundForEveryBar(arrayData, categories, maximum);

		let chartHeight = BAR_HEIGHT * categories.length;

		if (showXAxis) {
			chartHeight += 25;
		}

		const config = merge(getSharedBarChartStyles(), {
			chart: {
				backgroundColor: 'transparent',
				// height depends on about of categories
				height: chartHeight,
				renderTo: 'container',
				spacing: 0,
				margin: showXAxis ? [0, 0, 25] : [0, 0, 0],
				type: 'bar',
			},
			series: arrayData,
			plotOptions: {
				series: {
					borderWidth: 2,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
			},
		});

		config.plotOptions.series.stacking = 'normal';

		config.tooltip = merge(config.tooltip, {
			formatter: function () {
				// tooltip can be defined via custom renderer
				if (tooltipRenderer) {
					return tooltipRenderer(this);
				}

				// check presence of custom tooltip in data definition
				if (this.point.tooltip) {
					return this.point.tooltip;
				}

				return this.x + ': ' + this.y;
			},
		});

		config.xAxis.categories = categories;

		config.yAxis = merge(config.yAxis, {
			endOnTick: false,
			labels: {
				autoRotation: false,
				formatter: function () {
					// Label can be defined via custom renderer.
					// Axes are reverted because of stacked type of chart so we can use here
					// xAxisLabelRenderer (it's more clear from outside of component).
					if (xAxisLabelRenderer) {
						return xAxisLabelRenderer(this);
					}

					return this.value;
				},
			},
			min: 0,
			minTickInterval: 1,
			max: maximum,
			tickColor: 'rgba(232,232,232,0.7)',
			title: {
				text: null,
			},
		});

		return (
			<HighchartsReact
				containerProps={{
					className: 'bar-chart',
				}}
				highcharts={Highcharts}
				options={config}
				ref={this.chartRef}
			/>
		);
	}

}

StackedBarChart.defaultProps = {
	minimum: 0,
	showXAxis: false,
};

StackedBarChart.propTypes = {
	/** Data categories (important info for grouping of data) */
	categories: PropTypes.array.isRequired,
	/** Chart data */
	data: PropTypes.object.isRequired,
	/** Maximum displayed value */
	maximum: PropTypes.number.isRequired,
	/** Minimum displayed value */
	minimum: PropTypes.number.isRequired,
	/** Possibility to display x-axes which is hidden by default */
	showXAxis: PropTypes.bool,
	/** Custom renderer of tooltips */
	tooltipRenderer: PropTypes.func,
	/** Set custom renderer of labels on x-axis according to specs https://api.highcharts.com/highcharts/yAxis.labels.format */
	xAxisLabelRenderer: PropTypes.func,
};



export default StackedBarChart;
