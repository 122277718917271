import {
	isSameDay,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ClickableArea from '~/components/patterns/content/ClickableArea';
import NoDataMessage from './NoDataMessage';
import ScoreNumber, {
	ScoreNumberSize,
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useNumberOfIssuesWidgetQuery,
} from './NumberOfIssuesWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToHistoricalIssues from '~/hooks/useNavigateToHistoricalIssues';
import useNavigateToIssues from '~/hooks/useNavigateToIssues';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateRangeForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLinkChanges: {
		id: 'ui.dashboardNumberOfIssuesWidget.primaryActionLink.changes',
	},
	primaryActionLinkCurrent: {
		id: 'ui.dashboardNumberOfIssuesWidget.primaryActionLink.current',
	},
	title: {
		id: 'ui.dashboardNumberOfIssuesWidget.title',
	},
});



const NumberOfIssuesWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const navigateToHistoricalIssues = useNavigateToHistoricalIssues();
	const navigateToIssues = useNavigateToIssues();
	const scopeSection = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data: metricData,
		loading,
		reasonForNoData,
	} = useDashboardQuery('numberOfIssues', useNumberOfIssuesWidgetQuery, {
		variables: {
			scope: scopeSection.scope,
			websiteId,
			...formatDateRangeForQuery(dateRange),
		},
	});

	const isCurrent = isSameDay(
		Date.now(),
		dateRange.end,
	);

	const primaryActionLinkRoute = isCurrent
		? navigateToIssues({
			scope: scopeSection.scope,
		})
		: navigateToHistoricalIssues({
			endDate: dateRange.end,
			scope: scopeSection.scope,
			startDate: dateRange.start,
		});

	return (
		<WidgetCell type={WidgetType.Issues}>
			<Widget
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={metricData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages[isCurrent ? 'primaryActionLinkCurrent' : 'primaryActionLinkChanges']} />
					),
					...primaryActionLinkRoute,
				}}
				showEndDate={true}
			>
				{metricData !== null ? (
					<ClickableArea
						routeName={primaryActionLinkRoute.routeName}
						routeParams={primaryActionLinkRoute.routeParams}
					>
						<ScoreNumber
							size={ScoreNumberSize.XLarge}
							style={ScoreNumberStyle.GreyscaleDark}
							value={metricData.numberOfIssuesOnEndDate}
						/>
					</ClickableArea>
				) : null}
			</Widget>
		</WidgetCell>
	);
};



export default NumberOfIssuesWidget;
