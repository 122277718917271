import {
	produce,
} from 'immer';
import {
	type StoreApi,
	create,
} from 'zustand';



type ModifyState<TState> = (modifyCallback: (state: TState) => void) => void;

type CreateCallback<TState extends unknown> = (value: {
	get: StoreApi<TState>['getState'],
	modify: ModifyState<TState>,
	set: StoreApi<TState>['setState'],
}) => TState;



function createStore<TState extends unknown>(createCallback: CreateCallback<TState>) {
	return create<TState>((set, get) => createCallback({
		get,
		set,
		modify: (modifyCallback: (state: TState) => void) => {
			set((state) => produce(state, modifyCallback));
		},
	}));
}



export default createStore;
