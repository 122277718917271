import React from 'react';

import {
	PendoContext,
} from '~/components/providers/PendoProvider';



function usePendoContext() {
	return React.useContext(PendoContext);
}



export default usePendoContext;
