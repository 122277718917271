import {
	isApolloError,
} from '@apollo/client';
import {
	captureException,
} from '@sentry/browser';
import classNames from 'classnames';
import React from 'react';

import {
	EditableFormWrapperContext,
} from '~/components/atoms/forms/basis/EditableFormWrapperBase';

import useStableReference from '~/hooks/useStableReference';
import useStateCallback from '~/hooks/useStateCallback';

import FormError from '~/utilities/FormError';
import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';

import {
	NotApplicable,
	type Rule,
	type Values,
	isFieldRule,
	isFieldsRule,
	isGlobalRule,
} from '~/utilities/validations';



type FormState = Readonly<{
	clickInProgress: boolean,
	dataHasChanged: boolean,
	focused: string | null,
	globalError: FormError | null,
	interacted: Record<string, true>,
	isSubmitting: boolean,
	values: Values,
}>;

export type FormOnChangeHandlerOptions = {
	interacted?: boolean,
	timeout?: number,
};

export type FormOnMountHandlerOptions = {
	interacted?: boolean,
	setValues?: boolean,
	value?: any,
};

type FormContext = Readonly<{
	clickInProgress: boolean,
	dataHasChanged: boolean,
	defaultValues: Values,
	errors: Record<string, {
		message: React.ReactNode,
		validation: Rule,
	}>,
	focused: string | null,
	globalError: FormError | null,
	interacted: Record<string, true>,
	isDisabled: boolean,
	isSubmitting: boolean,
	isValid: boolean,
	ok: Record<string, {
		validation: Rule,
	}>,
	onBlurHandler: (field: string) => void,
	onChangeHandler: (field: string, value: any, options?: FormOnChangeHandlerOptions) => Promise<void>,
	onFocusHandler: (field: string) => void,
	onMountHandler: (field: string, options: FormOnMountHandlerOptions) => void,
	onUnmountHandler: (field: string) => void,
	registerValidations: (id: string, validations: Record<string, ReadonlyArray<Rule>>) => void,
	resetGlobalError: (exceptGlobalErrors?: ReadonlyArray<string>) => void,
	setValues: (values: Values) => Promise<void>,
	submit: () => void,
	values: Values,
	valuesSelector: (values: Values) => Values,
}>;

const defaultValuesSelector = (values: Values) => values;

const Context = React.createContext<FormContext>({
	clickInProgress: false,
	dataHasChanged: false,
	defaultValues: {},
	errors: {},
	focused: null,
	globalError: null,
	interacted: {},
	isDisabled: false,
	isSubmitting: false,
	isValid: true,
	ok: {},
	onBlurHandler: () => {},
	onChangeHandler: () => Promise.resolve(),
	onFocusHandler: () => {},
	onMountHandler: () => {},
	onUnmountHandler: () => {},
	registerValidations: () => {},
	resetGlobalError: () => {},
	setValues: () => Promise.resolve(),
	submit: () => {},
	values: {},
	valuesSelector: defaultValuesSelector,
});

const FallbackDefaultValues = {};
const FallbackValidations = {};



function convertErrorsToMessages(errors) {
	const errorMessages = {};
	for (const name in errors) {
		if (errors.hasOwnProperty(name)) {
			errorMessages[name] = errors[name].message;
		}
	}

	return errorMessages;
}



function runValidations(input: {
	globalError: FormError | null,
	validations: Record<string, ReadonlyArray<Rule>>,
	values: Values,
}) {
	const {
		globalError,
		validations,
		values,
	} = input;

	const errors: Record<string, {
		message: React.ReactNode,
		validation: Rule,
	}> = {};

	const ok: Record<string, {
		validation: Rule,
	}> = {};

	const allValidations = validations;

	for (const [name, rules] of Object.entries(validations)) {
		let isPassing = false;
		let isFailing = false;

		for (const rule of rules) {
			const {
				message,
			} = rule;

			let result: boolean | typeof NotApplicable = true;

			if (isGlobalRule(rule)) {
				result = (
					globalError !== null
					&& rule.globalRule === globalError.getName()
					&& (
						globalError.getValue() === null
						|| globalError.getValue() === values[rule.field]
					)
				) === false;
			} else {
				result = rule.rule({
					name,
					value: values[name],
					values: { ...values },
				});
			}

			if (result === NotApplicable) {
				isPassing = true;
				break;
			}

			if (result !== true) {
				errors[name] = {
					message: renderProp(message, {
						details: (globalError && globalError.details) || {},
						globalError,
						name,
						value: values[name],
						values,
					}),
					validation: rule,
				};
				delete ok[name];

				isFailing = true;
				break;
			} else {
				isPassing = true;
			}
		}

		if (!isFailing && isPassing) {
			delete errors[name];
			ok[name] = {
				validation: getArrayItemAtSafeIndex(rules, 0),
			};
		}
	}

	for (const name in errors) {
		if (errors.hasOwnProperty(name) && !allValidations.hasOwnProperty(name)) {
			delete errors[name];
		}
	}

	for (const name in ok) {
		if (ok.hasOwnProperty(name) && !allValidations.hasOwnProperty(name)) {
			delete ok[name];
		}
	}

	const isValidBasedOnErrors = Object.values(errors).length === 0;

	return {
		errors,
		isSubmittable: isValidBasedOnErrors,
		isValid: isValidBasedOnErrors,
		ok,
	};
}



export type FormRef = {
	changeFieldValue: (field: string, value: any) => Promise<void>,
	getValues: () => Values,
	resetGlobalError: (exceptGlobalErrors?: ReadonlyArray<string>) => void,
	setValue: (field: string, value: any) => void,
	setValues: (values: Values) => Promise<void>,
	submit: () => void,
	validateValues: (values: Values) => ReturnType<typeof runValidations>,
};



type Props = {
	children: RenderProp<{
		dataHasChanged: boolean,
		defaultValues: Values,
		errors: Record<string, React.ReactNode>,
		focused: string | null,
		globalError: FormError | null,
		isDisabled: boolean,
		isSubmittable: boolean,
		isSubmitting: boolean,
		isValid: boolean,
		onChangeHandler: (field: string, value: any, options: FormOnChangeHandlerOptions) => Promise<void>,
		resetGlobalError: () => void,
		setValues: (values: Values) => Promise<void>,
		submit: () => void,
		values: Values,
	}>,
	className?: string,
	clearOnFieldUnmount?: boolean,
	defaultDataHasChanged?: boolean,
	defaultFocus?: string | null,
	defaultValues?: Values,
	/**
	 * When fields unmount their value, errors and state are removed normally.
	 * Setting this prop to `true` will ignore unmounting of fields and keep their
	 * state. This is useful for virtualized grids with fields in them.
	 */
	ignoreFieldUnmounts?: boolean,
	/** Make whole form and fields disabled */
	isDisabled?: boolean,
	onBlurCallback?: (field: string) => void,
	onChangeCallback?: (
		field: string,
		value: any,
		values: Values,
	) => void,
	onFocusCallback?: (field: string) => void,
	onSuccess?: (values: Values, helpers: {
		createError: (name, options?: {
			details?: any,
			value?: any,
		}) => FormError,
	}) => Promise<any> | void,
	overrideDataHasChanged?: boolean | null,
	validations?: Record<string, ReadonlyArray<Rule>>,
};

const Form = React.forwardRef<FormRef, Props>((props, ref) => {
	const {
		children,
		className,
		clearOnFieldUnmount = false,
		defaultDataHasChanged = false,
		defaultFocus = null,
		defaultValues: actualDefaultValues = FallbackDefaultValues,
		ignoreFieldUnmounts = false,
		isDisabled = false,
		onBlurCallback,
		onChangeCallback,
		onFocusCallback,
		onSuccess,
		overrideDataHasChanged = null,
		validations: actualValidations = FallbackValidations,
	} = props;

	const defaultValues = useStableReference(actualDefaultValues);

	const editableFormWrapperContext = React.useContext(EditableFormWrapperContext);

	const editableFormWrapperContextCloseEditModeHandler = editableFormWrapperContext?.closeEditModeHandler;

	const [state, setState] = useStateCallback<FormState>(
		() => {
			return {
				clickInProgress: false,
				dataHasChanged: defaultDataHasChanged,
				focused: defaultFocus,
				globalError: null,
				interacted: {},
				isSubmitting: false,
				values: { ...defaultValues },
			};
		},
	);

	const {
		clickInProgress,
		dataHasChanged,
		focused,
		globalError,
		interacted,
		isSubmitting,
		values,
	} = state;

	const [extraValidations, setExtraValidations] = React.useState<Record<string, Record<string, Array<Rule>>>>({});

	const validations = React.useMemo(
		() => {
			const result: Record<string, Array<Rule>> = {};

			for (const extraValidationsSet of Object.values(extraValidations)) {
				for (const [name, rules] of Object.entries(extraValidationsSet)) {
					const existingRules = result[name];

					result[name] = existingRules
						? existingRules.concat(rules)
						: rules;
				}
			}

			for (const [name, rules] of Object.entries(actualValidations)) {
				const existingRules = result[name];

				result[name] = existingRules
					? [...existingRules, ...(rules as Array<Rule>)]
					: [...(rules as Array<Rule>)];
			}

			return result;
		},
		[
			actualValidations,
			extraValidations,
		],
	);

	const formRef = React.useRef<HTMLFormElement>(null);
	const isMountedRef = React.useRef(false);
	const timeoutsRef = React.useRef<Record<string, ReturnType<typeof setTimeout>>>({});
	const unmountActionRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
	const unmountFieldRef = React.useRef<string | null>(null);

	React.useEffect(
		() => {
			isMountedRef.current = true;

			return () => {
				for (const timeout of Object.values(timeoutsRef.current)) {
					clearTimeout(timeout);
				}

				timeoutsRef.current = {};

				isMountedRef.current = false;
			};
		},
		[],
	);

	const setValue = React.useCallback(
		(field: string, value) => {
			return new Promise<void>((resolve) => {
				setState(
					(state) => ({
						...state,
						values: {
							...state.values,
							[field]: value,
						},
					}),
					() => resolve(),
				);
			});
		},
		[
			setState,
		],
	);

	const setValues = React.useCallback(
		(values: Values) => {
			return new Promise<void>((resolve) => {
				setState(
					(state) => ({
						...state,
						dataHasChanged: true,
						values: {
							...state.values,
							...values,
						},
					}),
					() => resolve(),
				);
			});
		},
		[
			setState,
		],
	);

	const onBlurHandler = React.useCallback(
		(field: string) => {
			if (focused === field) {
				setState(
					(state) => ({
						...state,
						focused: null,
						interacted: {
							...state.interacted,
							[field]: true,
						},
					}),
				);

				if (onBlurCallback) {
					onBlurCallback(field);
				}
			}
		},
		[
			focused,
			onBlurCallback,
			setState,
		],
	);

	const onChangeHandler = React.useCallback(
		(field: string, value: any, options: FormOnChangeHandlerOptions = {}) => {
			return new Promise<void>((resolve) => {
				setState(
					(state) => ({
						...state,
						dataHasChanged: true,
						values: {
							...state.values,
							[field]: value,
						},
					}),
					(state) => {
						if (options.interacted !== false) {
							if (options.timeout) {
								if (timeoutsRef.current[field]) {
									clearTimeout(timeoutsRef.current[field]);
								}

								timeoutsRef.current[field] = setTimeout(() => {
									delete timeoutsRef.current[field];

									setState(
										(state) => ({
											...state,
											interacted: {
												...state.interacted,
												[field]: true,
											},
										}),
									);
								}, options.timeout);
							} else {
								setState(
									(state) => ({
										...state,
										interacted: {
											...state.interacted,
											[field]: true,
										},
									}),
								);
							}
						}

						if (onChangeCallback) {
							onChangeCallback(
								field,
								value,
								{ ...state.values },
							);
						}

						resolve();
					},
				);
			});
		},
		[
			onChangeCallback,
			setState,
		],
	);

	const onFocusHandler = React.useCallback(
		(field: string) => {
			setState(
				(state) => ({
					...state,
					focused: field,
				}),
			);

			if (onFocusCallback) {
				onFocusCallback(field);
			}
		},
		[
			onFocusCallback,
			setState,
		],
	);

	const defaultValuesRef = React.useRef(defaultValues);
	defaultValuesRef.current = defaultValues;

	const onMountHandler = React.useCallback(
		(field: string, options: {
			interacted?: boolean,
			setValues?: boolean,
			value?: any,
		} = {}) => {
			if (
				unmountFieldRef.current === field
				&& unmountActionRef.current !== null
			) {
				// prevent unmounting and mounting again the same field
				clearTimeout(unmountActionRef.current);
			}

			if (options.interacted || options.setValues || options.value !== undefined) {
				setState(
					(state) => {
						const newState = { ...state };

						if (options.interacted) {
							newState.interacted = {
								...state.interacted,
								[field]: true,
							};
						}

						if (options.setValues) {
							newState.values = {
								...state.values,
								[field]: state.values[field] === undefined
									? defaultValuesRef.current[field]
									: state.values[field],
							};
						} else if (options.value !== undefined) {
							newState.values = {
								...state.values,
								[field]: options.value,
							};
						}

						return newState;
					},
				);
			}
		},
		[
			setState,
		],
	);

	const onMouseDownHandler = React.useCallback(
		() => {
			setState(
				(state) => ({
					...state,
					clickInProgress: true,
				}),
			);
		},
		[
			setState,
		],
	);

	const onMouseUpHandler = React.useCallback(
		() => {
			setState(
				(state) => ({
					...state,
					clickInProgress: false,
				}),
			);
		},
		[
			setState,
		],
	);

	const onChangeCallbackRef = React.useRef(onChangeCallback);
	onChangeCallbackRef.current = onChangeCallback;

	const onUnmountHandler = React.useCallback(
		(field: string) => {
			if (ignoreFieldUnmounts) {
				return;
			}

			unmountFieldRef.current = field;
			unmountActionRef.current = setTimeout(() => {
				if (isMountedRef.current === false) {
					return;
				}

				setState(
					(state) => {
						const newState = {
							...state,
							interacted: { ...state.interacted },
							values: { ...state.values },
						};

						delete newState.interacted[field];
						delete newState.values[field];

						if (state.focused === field) {
							newState.focused = null;
						}

						return newState;
					},
					(state) => {
						if (onChangeCallbackRef.current && clearOnFieldUnmount) {
							onChangeCallbackRef.current(
								field,
								null,
								{ ...state.values },
							);
						}
					},
				);
			}, 1);
		},
		[
			clearOnFieldUnmount,
			ignoreFieldUnmounts,
			setState,
		],
	);

	React.useEffect(
		() => {
			document.addEventListener('mousedown', onMouseDownHandler);
			document.addEventListener('mouseup', onMouseUpHandler);

			return () => {
				document.removeEventListener('mousedown', onMouseDownHandler);
				document.removeEventListener('mouseup', onMouseUpHandler);
			};
		},
		[
			onMouseDownHandler,
			onMouseUpHandler,
		],
	);

	React.useEffect(
		() => {
			setState(
				(state) => {
					const newValues = { ...state.values };

					let modify = false;

					for (const name of Object.keys(defaultValues)) {
						if (!newValues.hasOwnProperty(name) || (clearOnFieldUnmount && (newValues[name] === null || newValues[name] === undefined))) {
							newValues[name] = defaultValues[name];

							modify = true;
						}
					}

					if (modify === false) {
						return state;
					}

					return {
						...state,
						values: newValues,
					};
				},
			);
		},
		[
			clearOnFieldUnmount,
			defaultValues,
			setState,
		],
	);

	const registerValidations = React.useCallback(
		(id: string, validations: Record<string, Array<Rule>>) => {
			setExtraValidations(
				(extraValidations) => {
					if (Object.values(validations).length === 0) {
						const newExtraValidations = { ...extraValidations };
						delete newExtraValidations[id];
						return newExtraValidations;
					}

					return {
						...extraValidations,
						[id]: validations,
					};
				},
			);
		},
		[],
	);

	const resetGlobalError = React.useCallback(
		(exceptGlobalErrors?: ReadonlyArray<string>) => {
			setState(
				(state) => {
					if (state.globalError === null) {
						return state;
					}

					if (exceptGlobalErrors?.includes(state.globalError.getName())) {
						return state;
					}

					return {
						...state,
						globalError: null,
					};
				},
			);
		},
		[
			setState,
		],
	);

	const {
		errors,
		isSubmittable,
		isValid,
		ok,
	} = React.useMemo(
		() => {
			if (isDisabled) {
				return {
					errors: {},
					isSubmittable: false,
					isValid: true,
					ok: {},
				};
			}

			return runValidations({
				globalError,
				validations,
				values,
			});
		},
		[
			globalError,
			isDisabled,
			validations,
			values,
		],
	);

	const submit = React.useCallback(
		() => {
			if (isDisabled) {
				return false;
			}

			if (isValid) {
				if (onSuccess) {
					const result = onSuccess({ ...values }, {
						createError: (name, {
							details = true,
							value = null,
						} = {}) => {
							return new FormError(
								name,
								value,
								details,
							);
						},
					});

					if (result) {
						setState(
							(state) => ({
								...state,
								isSubmitting: true,
							}),
						);

						result.then(() => {
							if (isMountedRef.current === false) {
								return;
							}

							setState(
								(state) => {
									const updatedState = {
										...state,
										isSubmitting: false,
									};

									if (!editableFormWrapperContextCloseEditModeHandler) {
										updatedState.globalError = null;
									}

									return updatedState;
								},
							);

							if (editableFormWrapperContextCloseEditModeHandler) {
								editableFormWrapperContextCloseEditModeHandler(true);
							}
						}).catch((e) => {
							/* eslint-disable no-console */
							if (e === undefined || e === null) {
								console.log('Form.onSuccess failed', { e, values });
								captureException('Form.catch caught undefined error');
							} else if (isApolloError(e)) {
								e = FormError.fromApolloError(e);
							} else if (!(e instanceof FormError)) {
								console.log('Form.onSuccess failed', e);
							}
							/* eslint-enable no-console */

							if (isMountedRef.current === false) {
								return;
							}

							setState(
								(state) => {
									let interacted = state.interacted;

									// never worked before
									if (state.focused !== null && !state.interacted[state.focused]) {
										interacted = {
											...interacted,
											[state.focused]: true,
										};
									}

									const updatedState = {
										...state,
										interacted,
										isSubmitting: false,
									};

									if (e instanceof FormError) {
										updatedState.globalError = e;
									}

									return updatedState;
								},
							);
						});
					} else {
						if (editableFormWrapperContextCloseEditModeHandler) {
							editableFormWrapperContextCloseEditModeHandler(true);
						}
					}
				} else {
					if (editableFormWrapperContextCloseEditModeHandler) {
						editableFormWrapperContextCloseEditModeHandler(true);
					}
				}
			} else {
				setState(
					(state) => {
						let interacted = { ...state.interacted };

						for (const rules of Object.values(validations)) {
							for (const rule of rules as ReadonlyArray<Rule>) {
								if (isFieldRule(rule)) {
									interacted = {
										...interacted,
										[rule.field]: true,
									};
								} else if (isFieldsRule(rule)) {
									for (const validationField of rule.fields) {
										interacted = {
											...interacted,
											[validationField]: true,
										};
									}
								}
							}
						}

						return {
							...state,
							interacted,
						};
					},
				);
			}
		},
		[
			editableFormWrapperContextCloseEditModeHandler,
			isDisabled,
			isValid,
			onSuccess,
			setState,
			validations,
			values,
		],
	);

	const handleSubmit = React.useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			submit();
		},
		[
			submit,
		],
	);

	React.useImperativeHandle(ref, () => ({
		changeFieldValue: onChangeHandler,
		getValues: () => {
			return { ...values };
		},
		resetGlobalError,
		setValue,
		setValues,
		submit,
		validateValues: (values: Values) => {
			return runValidations({
				globalError: state.globalError,
				validations,
				values: {
					...state.values,
					...values,
				},
			});
		},
	}));

	const formContext = React.useMemo(
		() => ({
			clickInProgress,
			dataHasChanged: overrideDataHasChanged ?? dataHasChanged,
			defaultValues,
			errors,
			focused,
			globalError,
			interacted,
			isDisabled,
			isSubmitting,
			isValid,
			ok,
			onBlurHandler,
			onChangeHandler,
			onFocusHandler,
			onMountHandler,
			onUnmountHandler,
			registerValidations,
			resetGlobalError,
			setValues,
			submit,
			values,
			valuesSelector: defaultValuesSelector,
		}),
		[
			clickInProgress,
			dataHasChanged,
			defaultValues,
			errors,
			focused,
			globalError,
			interacted,
			isDisabled,
			isSubmitting,
			isValid,
			ok,
			onBlurHandler,
			onChangeHandler,
			onFocusHandler,
			onMountHandler,
			onUnmountHandler,
			overrideDataHasChanged,
			registerValidations,
			resetGlobalError,
			setValues,
			submit,
			values,
		],
	);

	const componentClasses = classNames({
		'ck-form': true,
	}, className);

	return (
		<form
			className={componentClasses}
			noValidate={true}
			onSubmit={handleSubmit}
			ref={formRef}
		>
			<Context.Provider value={formContext}>
				{renderProp(children, {
					dataHasChanged: overrideDataHasChanged ?? dataHasChanged,
					defaultValues,
					errors: convertErrorsToMessages(errors),
					focused,
					globalError,
					isDisabled,
					isSubmittable,
					isSubmitting,
					isValid,
					onChangeHandler,
					resetGlobalError,
					setValues,
					submit,
					values,
				})}
			</Context.Provider>
		</form>
	);
});



export default Form;

export {
	Context as FormContext,
	FormContext as FormContextPayload,
	NotApplicable,
};
