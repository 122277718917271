import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import LinksStructureBlockPart from './LinksStructureBlockPart';
import OtherRelationsBlockPart from './OtherRelationsBlockPart';

import {
	useRelationsBlockPrimaryQuery,
	useRelationsBlockSrcQuery,
} from './RelationsBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageDetailSourceMode from '~/hooks/usePageDetailSourceMode';
import usePageUrl from '~/hooks/usePageUrl';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	title: {
		id: 'ui.pageDetail.relations.title',
	},
});



const RelationsBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const pageDetailSourceMode = usePageDetailSourceMode();
	const pageUrl = usePageUrl(legacyUrlId, websiteId);

	const {
		data: primaryData,
	} = usePageDetailPropertiesQuery(
		useRelationsBlockPrimaryQuery,
		legacyUrlId,
		websiteId,
	);

	const {
		data: srcData,
	} = usePageDetailPropertiesQuery(
		useRelationsBlockSrcQuery,
		legacyUrlId,
		websiteId,
		{
			skip: pageDetailSourceMode !== GraphQL.PageDetailSourceMode.Compare,
		},
	);

	const linkAlternateHreflangSitemap = primaryData?.lookupPageByLegacyId?.linkAlternateHreflangSitemap ?? null;
	const linkCanonicalHeaderData = primaryData?.lookupPageByLegacyId?.pageTypeData?.linkCanonicalHeader ?? null;
	const primaryLinkRelData = primaryData?.lookupPageByLegacyId?.pageTypeData?.primaryProperties ?? null;
	const srcLinkRelData = srcData?.lookupPageByLegacyId?.pageTypeData?.srcProperties ?? null;

	const hasLinkRels = (
		primaryLinkRelData !== null
		&& (
			primaryLinkRelData.linkAlternateHreflang.linkRelItems.length > 0
			|| primaryLinkRelData.linkAmp.linkRelItems.length > 0
			|| primaryLinkRelData.linkCanonical.linkRelItems.length > 0
			|| primaryLinkRelData.linkMobileVariant.linkRelItems.length > 0
			|| primaryLinkRelData.linkNext.linkRelItems.length > 0
			|| primaryLinkRelData.linkPrev.linkRelItems.length > 0
			|| (linkCanonicalHeaderData !== null && linkCanonicalHeaderData.linkRelItems.length > 0)
			|| (linkAlternateHreflangSitemap?.length ?? 0) > 0
		)
	);

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.Relations}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			paddingSize={3}
		>
			<LinksStructureBlockPart
				legacyUrlId={legacyUrlId}
				websiteId={websiteId}
			/>

			{hasLinkRels && (
				<OtherRelationsBlockPart
					compare={pageDetailSourceMode === GraphQL.PageDetailSourceMode.Compare}
					linkAlternateHreflangSitemap={linkAlternateHreflangSitemap}
					linkCanonicalHeaderData={linkCanonicalHeaderData}
					pageUrl={pageUrl}
					primaryLinkRelData={primaryLinkRelData}
					srcLinkRelData={srcLinkRelData}
					websiteId={websiteId}
				/>
			)}
		</BorderedBox>
	);
};



export default RelationsBlock;
