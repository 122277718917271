import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CodeValue from '~/components/patterns/values/CodeValue';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import SmallTable from '~/components/atoms/issues/components/detailsPlaceholders/SmallTable';
import Value from '~/components/logic/pageIssueDetail/Value';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	hreflang: {
		id: 'ui.invalidHreflangs.hreflang',
	},
	source: {
		id: 'ui.invalidHreflangs.source',
	},
	sourceDom: {
		id: 'ui.invalidHreflangs.sourceHtml',
	},
	sourceSrc: {
		id: 'ui.invalidHreflangs.sourceHtml',
	},
	target: {
		id: 'ui.invalidHreflangs.target',
	},
});



type Props = {
	issueContext: {
		instances: ReadonlyArray<{
			href: string,
			hreflang: string,
			sitemap_url: string | null,
			source: 'dom' | 'src' | 'sitemap',
		}>,
	},
	tableWidth: number,
};

const InvalidHreflangTargetsTable: React.FC<Props> = (props) => {
	const {
		issueContext,
		tableWidth,
	} = props;

	const firstColumnWidth = Math.floor(tableWidth * (1 / 4));
	const lastColumnWidth = tableWidth - firstColumnWidth - 2 - (tableWidth * (1 / 10));

	return (
		<SmallTable
			columns={[
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<CodeValue>
										{row.hreflang}
									</CodeValue>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.hreflang} />
						),
					},
					width: firstColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<Value>
										{row.href}
									</Value>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.target} />
						),
					},
					width: lastColumnWidth,
				},
				{
					render: {
						cell: ({ row }) => {
							return (
								<Ellipsis>
									<Value>
										{matchAndReturn(row.source, {
											dom: (
												<FormattedMessage {...messages.sourceDom} />
											),
											src: (
												<FormattedMessage {...messages.sourceSrc} />
											),
											sitemap: row.sitemap_url,
										})}
									</Value>
								</Ellipsis>
							);
						},
						header: () => (
							<FormattedMessage {...messages.source} />
						),
					},
					width: lastColumnWidth,
				},
			]}
			rows={issueContext.instances}
			tableWidth={tableWidth}
		/>
	);
};



export default React.memo(InvalidHreflangTargetsTable);
