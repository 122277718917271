import React from 'react';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FieldRowBuilderDisplaySlot from '~/components/app/FieldRowBuilderDisplaySlot';
import FieldRowBuilderFieldSlot from '~/components/app/FieldRowBuilderFieldSlot';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import SelectField from '~/components/atoms/forms/components/SelectField';
import UserAgentHeaderValue from '~/components/app/UserAgentHeaderValue';
import UserAgentName from '~/components/names/UserAgentName';
import {
	UserAgentMapping,
} from '~/components/app/UserAgentFields';

import useFormContext from '~/hooks/useFormContext';

import {
	UserAgent,
} from '~/model/websites/userAgents';



type Props = {
	changedSettings: ReadonlyArray<string>,
	userAgent: UserAgent,
};


const WebsiteAdminTestFetchViaRenderingUserAgentFieldRow: React.FC<Props> = (props) => {
	const {
		changedSettings,
		userAgent,
	} = props;

	const formContext = useFormContext();

	const userAgentOptions = UserAgentMapping
		.filter((userAgent) => userAgent !== UserAgent.Automatic)
		.map((userAgent) => {
			const name = userAgent;
			const label = <UserAgentName userAgent={userAgent} />;
			return {
				label,
				name,
			};
		});

	const selectedUserAgent = (formContext.values['userAgent'] ?? userAgent ?? null) as (UserAgent | null);
	const isEditable = changedSettings.includes('userAgent');

	return (
		<FormRow
			description={isEditable && selectedUserAgent !== UserAgent.Custom && (
				<UserAgentHeaderValue
					userAgent={selectedUserAgent}
				/>
			)}
			htmlFor="userAgent"
			label="User Agent"
		>
			<FieldStatus
				name="userAgent"
			>
				<FieldRowBuilderDisplaySlot />
				<FieldRowBuilderFieldSlot>
					<SelectField
						name="userAgent"
						options={userAgentOptions}
					/>
				</FieldRowBuilderFieldSlot>
			</FieldStatus>
		</FormRow>
	);
};



export default WebsiteAdminTestFetchViaRenderingUserAgentFieldRow;

