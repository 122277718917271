import Immutable from 'immutable';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import ChartContainer from '~/components/atoms/charts/components/ChartContainer';
import ChartTooltip from '~/components/patterns/charts/components/ChartTooltip';
import HealthLabel from '~/components/patterns/structuredValues/HealthLabel';
import IntervalOverviewChart from '~/components/atoms/charts/charts/IntervalOverviewChart';
import NoDataMessage from './NoDataMessage';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useHealthChangesWidgetQuery,
} from './HealthChangesWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToHistoricalIssues from '~/hooks/useNavigateToHistoricalIssues';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateRangeForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardHealthChangesWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardHealthChangesWidget.title',
	},
	tooltip: {
		id: 'ui.dashboardHealthChangesWidget.tooltip',
	},
});



const HealthChangesWidget: React.FC = () => {
	const intl = useIntl();

	const dateRange = useDashboardDateRange();
	const navigateToHistoricalIssues = useNavigateToHistoricalIssues();
	const scopeSection = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data,
		loading,
		reasonForNoData,
	} = useDashboardQuery('healthChanges', useHealthChangesWidgetQuery, {
		variables: {
			scope: scopeSection.scope,
			websiteId,
			...formatDateRangeForQuery(dateRange),
		},
	});

	const chartData = React.useMemo(
		() => {
			if (data === null) {
				return null;
			}

			if (data.length === 0) {
				return null;
			}

			return {
				data: data.map((item) => item.health),
				xAxisCaptions: Immutable.List(
					data.map((item) => intl.formatDate(new Date(item.date), {
						day: 'numeric',
						month: 'short',
						timeZone: 'Europe/Amsterdam',
					})),
				),
			};
		},
		[
			data,
			intl,
		],
	);

	const renderTooltip = React.useCallback(
		(formatter) => {
			return ReactDOMServer.renderToString(
				<ChartTooltip color={formatter.color}>
					{intl.formatMessage(messages.tooltip, { value: formatter.y })}
				</ChartTooltip>,
			);
		},
		[
			intl,
		],
	);

	return (
		<WidgetCell type={WidgetType.HealthChanges}>
			<Widget
				label={(
					<HealthLabel>
						<FormattedMessage {...messages.title} />
					</HealthLabel>
				)}
				loading={loading}
				overlay={chartData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					...navigateToHistoricalIssues({
						endDate: dateRange.end,
						scope: scopeSection.scope,
						startDate: dateRange.start,
					}),
				}}
			>
				{chartData !== null ? (
					<ChartContainer
						chart={(
							<IntervalOverviewChart
								data={[
									{
										color: '#FE8719',
										data: chartData.data,
									},
								]}
								disabled={false}
								maximum={1000}
								minimum={0}
								renderTooltipCallback={renderTooltip}
								xAxisCaptions={chartData.xAxisCaptions}
							/>
						)}
						height={140}
						name="health"
						padded={true}
						type="interval-overview-chart"
					/>
				) : null}
			</Widget>
		</WidgetCell>
	);
};



export default HealthChangesWidget;
