import {
	assert,
	assertString,
} from './utilities/typeCheck';



export const location = window.location;

export const atob = window.atob;

export const btoa = window.btoa;

export const Intercom = () => {
	// @ts-expect-error Intercom is untyped
	return window.Intercom;
};


let pendoPromise: Promise<any> | null = null;
export function loadPendo(): Promise<pendo.Pendo> {
	if (pendoPromise === null) {
		pendoPromise = new Promise((resolve) => {
			const pendoScript = document.getElementById('pendo');
			assert(pendoScript instanceof HTMLScriptElement, 'Pendo script not found');

			const pendoSrc = pendoScript.dataset.src;
			assertString(pendoSrc);

			pendoScript.onload = () => {
				// @ts-expect-error Pendo is untyped
				resolve(window.pendo);
			};

			pendoScript.setAttribute('src', pendoSrc);
		});
	}

	return pendoPromise;
}
