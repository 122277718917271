import React from 'react';

import useBatchContextDashboard from '~/hooks/useBatchContextDashboard';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useManuallyTrackedLoading from '~/hooks/useManuallyTrackedLoading';



function useDashboardQuery(mainField, useQueryHook, options) {
	const scopeSection = useDashboardScopeSection();
	const [pollInterval, setPollInterval] = React.useState(0);

	const manuallyTrackedLoading = useManuallyTrackedLoading();

	const skip = options.skip;

	const result = useQueryHook({
		...options,
		context: useBatchContextDashboard(options.variables.websiteId, options.variables.scope),
		pollInterval,
		skip,
		fetchPolicy: scopeSection.suspendDataFetching ? 'cache-only' : 'cache-first',
		nextFetchPolicy: 'cache-and-network',
		returnPartialdata: true,
	});

	const dashboardFieldPayload = (
		(result.data && result.data.dashboardData[mainField])
		?? (result.previousData && result.previousData.dashboardData[mainField])
		?? null
	);

	const data = dashboardFieldPayload !== null ? dashboardFieldPayload.data : null;

	React.useEffect(
		() => setPollInterval((dashboardFieldPayload && dashboardFieldPayload.pollInterval) || 0),
		[
			dashboardFieldPayload,
		],
	);

	React.useEffect(
		() => {
			if (
				data === null
				&& !result.loading
				&& !skip
				&& manuallyTrackedLoading !== null
			) {
				manuallyTrackedLoading.finish();
			}
		},
		[
			data,
			result.loading,
			skip,
			manuallyTrackedLoading,
		],
	);

	return {
		...result,
		data,
		// when skip is "true", loading remains "false",
		// so to prevent flash of "no data" message, we override loading to "true"
		loading: result.loading && !data,
		reasonForNoData: dashboardFieldPayload !== null ? dashboardFieldPayload.reasonForNoData : null,
	};
}



export default useDashboardQuery;
