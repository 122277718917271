import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import AttachedNote, {
	AttachedNoteAlignment,
} from '~/components/patterns/structuredValues/AttachedNote';
import BlankValue from '~/components/patterns/values/BlankValue';
import CodeValue from '~/components/patterns/values/CodeValue';
import ColumnValueFormatter from '~/components/logic/formatters/ColumnValueFormatter';
import ContentChangeComparison, {
	ContentChangeComparisonHighlightType,
} from '~/components/patterns/trackedChanges/ContentChangeComparison';
import CustomElementFormatter from '~/components/logic/formatters/CustomElementFormatter';
import DomSrcBadge, {
	DomSrcBadgeType,
} from '~/components/patterns/tags/DomSrcBadge';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import EmptyValue from '~/components/app/EmptyValue';
import EnrichmentFieldFormatter from '~/components/logic/formatters/EnrichmentFieldFormatter';
import MissingValue from '~/components/app/MissingValue';
import SearchEngineActivityBadges, {
	SearchEngineActivityBadgesWording,
} from '~/components/app/SearchEngineActivityBadges';
import Small from '~/components/patterns/typography/Small';
import StringSelectionHighlight, {
	StringSelectionHighlightType,
} from '~/components/patterns/values/StringSelectionHighlight';
import TextInspector from '~/components/patterns/typography/TextInspector';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';
import WebVitalsStatusIndicator, {
	WebVitalsStatusIndicatorStatus,
} from '~/components/patterns/webVitals/WebVitalsStatusIndicator';
import YesOrNo from '~/components/app/YesOrNo';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	LIST_OF_ALL_SERVICES,
} from '~/model/analyticServices';

import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	allowed: {
		id: 'ui.pageDetail.robotsTxt.allowed.yes',
	},
	disallowed: {
		id: 'ui.pageDetail.robotsTxt.allowed.no',
	},
});

const messagesTitle = defineMessages({
	adobe_analytics: {
		id: 'ui.contentData.adobe_analytics',
	},
	adobe_tag_manager: {
		id: 'ui.contentData.adobe_tag_manager',
	},
	canonicalToSelf: {
		id: 'ui.contentData.canonicalToSelf',
	},
	canonical: {
		id: 'ui.contentData.canonical',
	},
	canonical_link: {
		id: 'ui.contentData.canonical_link',
	},
	clicktale: {
		id: 'ui.contentData.clicktale',
	},
	clicky: {
		id: 'ui.contentData.clicky',
	},
	contentsquare: {
		id: 'ui.contentData.contentsquare',
	},
	crazy_egg: {
		id: 'ui.contentData.crazy_egg',
	},
	google_analytics: {
		id: 'ui.contentData.google_analytics',
	},
	google_tag_manager: {
		id: 'ui.contentData.google_tag_manager',
	},
	h1: {
		id: 'ui.contentData.h1',
	},
	h2: {
		id: 'ui.contentData.h2',
	},
	h3: {
		id: 'ui.contentData.h3',
	},
	h4: {
		id: 'ui.contentData.h4',
	},
	h5: {
		id: 'ui.contentData.h5',
	},
	h6: {
		id: 'ui.contentData.h6',
	},
	hotjar: {
		id: 'ui.contentData.hotjar',
	},
	inspectlet: {
		id: 'ui.contentData.inspectlet',
	},
	is_disallowed_in_robots_txt: {
		id: 'ui.contentOverview.tableHeading.is_disallowed_in_robots_txt',
	},
	lighthouse_cls_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_cumulative_layout_shift',
	},
	lighthouse_fcp_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_first_contentful_paint',
	},
	lighthouse_lcp_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_largest_contentful_paint',
	},
	lighthouse_performance_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_performance',
	},
	lighthouse_si_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_speed_index',
	},
	lighthouse_tbt_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_total_blocking_time',
	},
	lighthouse_tti_range: {
		id: 'ui.contentOverview.tableHeading.lighthouse_time_to_interactive',
	},
	link_alternate_hreflang: {
		id: 'ui.contentData.link_alternate_hreflang',
	},
	link_alternate_hreflang_sitemap: {
		id: 'ui.contentData.link_alternate_hreflang',
	},
	link_amp: {
		id: 'ui.contentData.link_amp',
	},
	link_canonical_header: {
		id: 'ui.contentData.linkCanonicalHeader',
	},
	link_next: {
		id: 'ui.contentData.link_next',
	},
	link_prev: {
		id: 'ui.contentData.link_prev',
	},
	mobile_variant: {
		id: 'ui.contentData.mobile_variant',
	},
	mouseflow: {
		id: 'ui.contentData.mouseflow',
	},
	is_in_sitemap: {
		id: 'ui.contentData.is_in_sitemap',
	},
	is_indexable: {
		id: 'ui.contentData.is_indexable',
	},
	is_secured: {
		id: 'ui.contentData.is_secured',
	},
	last_unreliable_response: {
		id: 'ui.contentData.type',
	},
	loadingDetails: {
		id: 'ui.contentData.loadingDetails',
	},
	meta_bingbot: {
		id: 'ui.contentData.meta_bingbot',
	},
	meta_description: {
		id: 'ui.contentData.meta_description',
	},
	meta_googlebot: {
		id: 'ui.contentData.meta_googlebot',
	},
	meta_robots: {
		id: 'ui.contentData.meta_robots',
	},
	meta_slurp: {
		id: 'ui.contentData.meta_slurp',
	},
	meta_yandex: {
		id: 'ui.contentData.meta_yandex',
	},
	microsoft_clarity: {
		id: 'ui.contentData.microsoftClarity',
	},
	notLoadedYet: {
		id: 'ui.contentData.notLoadedYet',
	},
	open_graph_description: {
		id: 'ui.contentData.open_graph_description',
	},
	open_graph_image: {
		id: 'ui.contentData.open_graph_image',
	},
	open_graph_title: {
		id: 'ui.contentData.open_graph_title',
	},
	open_graph_type: {
		id: 'ui.contentData.open_graph_type',
	},
	open_graph_url: {
		id: 'ui.contentData.open_graph_url',
	},
	qualitativeAnalytics: {
		id: 'ui.contentData.qualitativeAnalytics',
	},
	quantitativeAnalytics: {
		id: 'ui.contentData.quantitativeAnalytics',
	},
	redirect: {
		id: 'ui.contentOverview.tableHeading.redirect',
	},
	schema_org_types: {
		id: 'ui.contentOverview.tableHeading.schemaOrgTypes',
	},
	segment_com_tag_manager: {
		id: 'ui.contentData.segmentComTagManager',
	},
	smartlook: {
		id: 'ui.contentData.smartlook',
	},
	tagManagement: {
		id: 'ui.contentData.tagManagement',
	},
	title: {
		id: 'ui.contentData.title',
	},
	type: {
		id: 'ui.contentData.type',
	},
	twitter_card: {
		id: 'ui.contentData.twitter_card',
	},
	twitter_description: {
		id: 'ui.contentData.twitter_description',
	},
	twitter_image: {
		id: 'ui.contentData.twitter_image',
	},
	twitter_site: {
		id: 'ui.contentData.twitter_site',
	},
	twitter_title: {
		id: 'ui.contentData.twitter_title',
	},
	url: {
		id: 'ui.contentData.url',
	},
	x_robots_tag: {
		id: 'ui.contentData.x_robots_tag',
	},
});

const messagesWebVitalRange = defineMessages({
	bad: {
		id: 'ui.screen.pagesGraphs.webVitals.poor',
	},
	good: {
		id: 'ui.screen.pagesGraphs.webVitals.good',
	},
	needsImprovement: {
		id: 'ui.screen.pagesGraphs.webVitals.needsImprovement',
	},
});

const messagesUnreliableResponse = defineMessages({
	started: {
		id: 'ui.pageDetail.history.unreliableResponse.started',
	},
	stopped: {
		id: 'ui.pageDetail.history.unreliableResponse.stopped',
	},
});



const typesWithPosition = [
	'h2',
	'h3',
	'h4',
	'h5',
	'h6',
];



type Props = {
	difference: ContentChangeComparisonHighlightType,
	newValue: any,
	nudgeOrUpsellLogFileAnalysis: boolean,
	oldValue?: any,
	position?: number,
	searchEngineActivity: ReadonlyArray<{
		searchEngine: GraphQL.SearchEngineActivitySearchEngine,
		status: GraphQL.SearchEngineActivityAtMomentStatus,
	}> | null,
	showTitle?: boolean,
	source?: string | null,
	textHighlight?: string | null,
	type: string,
};

const HistoricalChange: React.FC<Props> = (props) => {
	const {
		difference,
		newValue,
		nudgeOrUpsellLogFileAnalysis,
		oldValue,
		position,
		searchEngineActivity,
		showTitle = true,
		source = null,
		textHighlight = null,
		type,
	} = props;

	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	let newValueContent: React.ReactNode = newValue;
	let oldValueContent: React.ReactNode = oldValue;

	if (isString(newValue) && newValue !== '') {
		if (textHighlight && newValue.toLowerCase().includes(textHighlight.toLowerCase())) {
			const highlightStartIndex = newValue.toLowerCase().indexOf(textHighlight.toLowerCase());

			newValueContent = (
				<StringSelectionHighlight
					highlightEndIndex={highlightStartIndex + textHighlight.length}
					highlightStartIndex={highlightStartIndex}
					highlightType={StringSelectionHighlightType.Highlighted}
				>
					{newValue}
				</StringSelectionHighlight>
			);
		} else {
			newValueContent = (
				<TextInspector text={newValue} />
			);
		}
	}

	if (isString(oldValue) && oldValue !== '') {
		if (textHighlight && oldValue.toLowerCase().includes(textHighlight.toLowerCase())) {
			const highlightStartIndex = oldValue.toLowerCase().indexOf(textHighlight.toLowerCase());

			oldValueContent = (
				<StringSelectionHighlight
					highlightEndIndex={highlightStartIndex + textHighlight.length}
					highlightStartIndex={highlightStartIndex}
					highlightType={StringSelectionHighlightType.Highlighted}
				>
					{oldValue}
				</StringSelectionHighlight>
			);
		} else {
			oldValueContent = (
				<TextInspector text={oldValue} />
			);
		}
	}

	if (source === 'dom' && difference === ContentChangeComparisonHighlightType.Extracted) {
		oldValueContent = (
			<BlankValue>
				not extracted at the time
			</BlankValue>
		);
	}

	if (LIST_OF_ALL_SERVICES.includes(type)) {
		newValueContent = (newValue !== '')
			? newValue
			: 'present';

		oldValueContent = (oldValue !== '')
			? oldValue
			: 'present';
	}

	if (type === 'is_in_sitemap') {
		newValueContent = <YesOrNo state={newValue} />;
		oldValueContent = <YesOrNo state={oldValue} />;
	}

	if (type === 'is_indexable') {
		newValueContent = <YesOrNo state={newValue} />;
		oldValueContent = <YesOrNo state={oldValue} />;
	}

	if (type === 'is_disallowed_in_robots_txt') {
		newValueContent = newValue
			? (<FormattedMessage {...messages.disallowed} />)
			: (<FormattedMessage {...messages.allowed} />);

		oldValueContent = oldValue
			? (<FormattedMessage {...messages.disallowed} />)
			: (<FormattedMessage {...messages.allowed} />);
	}

	if (
		type === 'lighthouse_cls_range'
		|| type === 'lighthouse_fcp_range'
		|| type === 'lighthouse_lcp_range'
		|| type === 'lighthouse_performance_range'
		|| type === 'lighthouse_si_range'
		|| type === 'lighthouse_tbt_range'
		|| type === 'lighthouse_tti_range'
	) {
		const mapping = {
			good: WebVitalsStatusIndicatorStatus.Good,
			needsImprovement: WebVitalsStatusIndicatorStatus.NeedsImprovement,
			bad: WebVitalsStatusIndicatorStatus.Poor,
		};

		newValueContent = (
			<AttachedIcon
				icon={(
					<WebVitalsStatusIndicator
						status={mapping[newValue]}
					/>
				)}
			>
				<FormattedMessage {...messagesWebVitalRange[newValue]} />
			</AttachedIcon>
		);

		oldValueContent = (
			<AttachedIcon
				icon={(
					<WebVitalsStatusIndicator
						status={mapping[oldValue]}
					/>
				)}
			>
				<FormattedMessage {...messagesWebVitalRange[oldValue]} />
			</AttachedIcon>
		);
	}

	if (type === 'link_alternate_hreflang' || type === 'link_alternate_hreflang_sitemap') {
		if (newValue !== null && typeof newValue === 'object') {
			newValueContent = (
				<span>
					<CodeValue>{newValue.hreflang}</CodeValue>
					&nbsp;
					{newValue.href}
				</span>
			);
		}

		if (oldValue !== null && typeof oldValue === 'object') {
			oldValueContent = (
				<span>
					<CodeValue>{oldValue.hreflang}</CodeValue>
					&nbsp;
					{oldValue.href}
				</span>
			);
		}
	} else {
		if (newValue !== null && typeof newValue === 'object' && newValue.content !== undefined) {
			newValueContent = newValue.content;

			if (typeof newValueContent === 'string' && newValueContent !== '') {
				newValueContent = (
					<TextInspector text={newValueContent} />
				);
			}
		}

		if (oldValue !== null && typeof oldValue === 'object' && oldValue.content !== undefined) {
			oldValueContent = oldValue.content;

			if (typeof oldValueContent === 'string' && oldValueContent !== '') {
				oldValueContent = (
					<TextInspector text={oldValueContent} />
				);
			}
		}
	}

	if (type === 'last_unreliable_response') {
		if (difference === ContentChangeComparisonHighlightType.UnreliableResponse) {
			newValueContent = (
				<FormattedMessage
					{...messagesUnreliableResponse.started}
					values={{
						unreliableResponse: <UnreliableResponseName reason={newValue} />,
					}}
				/>
			);
		} else {
			newValueContent = (
				<FormattedMessage
					{...messagesUnreliableResponse.stopped}
					values={{
						unreliableResponse: <UnreliableResponseName reason={newValue} />,
					}}
				/>
			);
		}
	}

	if (type === 'schema_org_types') {
		newValueContent = (
			<ColumnValueFormatter
				column={CK.PagesCommonColumn.SchemaOrgTypes}
				value={newValue}
			/>
		);

		oldValueContent = (
			<ColumnValueFormatter
				column={CK.PagesCommonColumn.SchemaOrgTypes}
				value={oldValue}
			/>
		);
	}

	if (type.indexOf('custom_') === 0) {
		newValueContent = (
			<CustomElementFormatter
				column={type}
				customElements={true}
				useTextInspector={true}
				value={newValue}
			/>
		);

		oldValueContent = (
			<CustomElementFormatter
				column={type}
				customElements={true}
				useTextInspector={true}
				value={oldValue}
			/>
		);
	}

	if (type.indexOf('ef_') === 0) {
		newValueContent = (
			<EnrichmentFieldFormatter
				column={enrichmentFieldDefinitions.getById(type.substr(3))?.column ?? ''}
				useTextInspector={true}
				value={{
					isProvided: true,
					value: newValue,
				}}
			/>
		);

		oldValueContent = (
			<EnrichmentFieldFormatter
				column={enrichmentFieldDefinitions.getById(type.substr(3))?.column ?? ''}
				useTextInspector={true}
				value={{
					isProvided: true,
					value: oldValue,
				}}
			/>
		);
	}

	if (newValueContent === '') {
		newValueContent = (
			<EmptyValue />
		);
	} else if (newValueContent === false || newValueContent === null) {
		newValueContent = (
			<MissingValue />
		);
	}

	if (oldValueContent === '') {
		oldValueContent = (
			<EmptyValue />
		);
	} else if (oldValueContent === false || oldValueContent === null) {
		oldValueContent = (
			<MissingValue />
		);
	}

	return (
		<ContentChangeComparison
			badge={source !== null && (
				<DomSrcBadge type={source === 'dom' ? DomSrcBadgeType.Dom : DomSrcBadgeType.Src} />
			)}
			highlightType={difference}
			newContent={newValueContent}
			oldContent={difference !== ContentChangeComparisonHighlightType.Added && difference !== ContentChangeComparisonHighlightType.None && oldValueContent}
			searchEngineActivityStates={(
				<SearchEngineActivityBadges
					isCompact={true}
					searchEngineActivity={searchEngineActivity}
					showUpsellOrNudge={nudgeOrUpsellLogFileAnalysis}
					wording={SearchEngineActivityBadgesWording.VisitedBySearchEngine}
				/>
			)}
			title={showTitle && (
				<>
					{type.indexOf('custom_') === 0 ? (
						customElementDefinitions.getByName(type.substr(7))?.label ?? '-'
					) : type.indexOf('ef_') === 0 ? (
						enrichmentFieldDefinitions.getById(type.substr(3))?.label ?? '-'
					) : type === 'link_alternate_hreflang_sitemap' ? (
						<AttachedNote
							alignment={AttachedNoteAlignment.Right}
							note={(
								<Ellipsis>
									<Small>
										{newValue !== null ? newValue.sitemap_url : oldValue.sitemap_url}
									</Small>
								</Ellipsis>
							)}
						>
							<FormattedMessage {...messagesTitle[type]} />
						</AttachedNote>
					) : messagesTitle[type] ? (
						<FormattedMessage {...messagesTitle[type]} />
					) : (
						type
					)}
					{(typesWithPosition.includes(type) || (position !== undefined && position > 1)) && '#' + position}
				</>
			)}
		/>
	);
};



export {
	ContentChangeComparisonHighlightType as HistoricalChangeDifference,
};

export default HistoricalChange;
