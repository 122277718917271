import React from 'react';

import type CK from '~/types/contentking';

import {
	useCreateStaticSegmentMutation,
	useStaticSegmentUrlsQuery,
	useUpdateStaticSegmentMutation,
} from './useStaticSegmentEditorContext.gql';

import useIsPageSegmentUsedForIgnoring from '~/hooks/useIsPageSegmentUsedForIgnoring';
import usePendo from '~/hooks/usePendoContext';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	type SegmentDefinition,
	getSegmentDefinitionValidity,
} from '~/model/segments';



type State = {
	isDirty: boolean,
	isSubmitting: boolean,
	segmentColor: string | undefined,
	segmentIconName: string | null | undefined,
	segmentLabel: string | undefined,
	segmentShortcode: string | null | undefined,
	urls: ReadonlyArray<string> | null,
};



function useStaticSegmentEditorContext(input: {
	editedSegment: SegmentDefinition | null,
	websiteId: CK.WebsiteId,
}) {
	const {
		editedSegment,
		websiteId,
	} = input;

	const isPageSegmentUsedForIgnoring = useIsPageSegmentUsedForIgnoring(websiteId);
	const pendo = usePendo();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const { data } = useStaticSegmentUrlsQuery({
		skip: editedSegment === null,
		variables: {
			segmentId: editedSegment?.id ?? '',
			websiteId,
		},
	});

	const [createStaticSegment] = useCreateStaticSegmentMutation();
	const [updateStaticSegment] = useUpdateStaticSegmentMutation();

	const [state, setState] = React.useState<State>(() => ({
		isDirty: false,
		isSubmitting: false,
		segmentColor: undefined,
		segmentIconName: undefined,
		segmentLabel: undefined,
		segmentShortcode: undefined,
		urls: null,
	}));

	const segmentColor = state.segmentColor ?? editedSegment?.color ?? null;
	const segmentIconName = (state.segmentIconName === undefined ? editedSegment?.icon?.name : state.segmentIconName) ?? null;
	const segmentLabel = state.segmentLabel ?? editedSegment?.label ?? null;
	const segmentShortcode = (state.segmentShortcode === undefined ? editedSegment?.shortcode : state.segmentShortcode) ?? null;
	const segmentUrls = state.urls ?? data?.staticPageSegmentUrls?.urls ?? null;

	const {
		isDirty,
		isSubmitting,
	} = state;

	const validity = React.useMemo(
		() => {
			return getSegmentDefinitionValidity({
				currentFilterDefinition: {
					static: true,
				},
				currentSizeLimit: null,
				editedSegment,
				isPageSegmentUsedForIgnoring,
				segmentColor,
				segmentDefinitions: segmentDefinitions.listAll(),
				segmentIconName,
				segmentLabel,
				segmentShortcode,
			});
		},
		[
			editedSegment,
			isPageSegmentUsedForIgnoring,
			segmentColor,
			segmentDefinitions,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
		],
	);

	const updateIdentifier = React.useCallback(
		({
			segmentColor,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
		}) => {
			setState(
				(state) => ({
					...state,
					isDirty: true,
					segmentColor,
					segmentIconName,
					segmentLabel,
					segmentShortcode,
				}),
			);
		},
		[],
	);

	const updateUrls = React.useCallback(
		(urls: ReadonlyArray<string>) => {
			setState(
				(state) => ({
					...state,
					isDirty: true,
					urls,
				}),
			);
		},
		[],
	);

	const saveSegment = React.useCallback(
		async (props: Record<string, any> = {}): Promise<SegmentDefinition> => {
			const segmentData = {
				color: props.segmentColor ?? segmentColor,
				iconName: props.segmentIconName ?? segmentIconName,
				label: props.segmentLabel ?? segmentLabel,
				shortcode: props.segmentShortcode ?? segmentShortcode,
				urls: props.urls ?? segmentUrls,
				websiteId,
			};

			setState(
				(state) => ({
					...state,
					isSubmitting: true,
				}),
			);

			let savedSegment: SegmentDefinition;

			if (editedSegment !== null) {
				const { data } = await updateStaticSegment({
					variables: {
						...segmentData,
						segmentId: editedSegment.id,
					},
				});

				if (data?.UpdateStaticSegment.updatedPageSegment === undefined) {
					throw new Error(
						`Segment hasn't been retrieved after update mutation`,
					);
				}

				savedSegment = data.UpdateStaticSegment.updatedPageSegment;
			} else {
				const { data } = await createStaticSegment({
					variables: segmentData,
				});

				if (data?.CreateStaticSegment.createdPageSegment === undefined) {
					throw new Error(
						`Segment hasn't been retrieved after create mutation`,
					);
				}

				savedSegment = data.CreateStaticSegment.createdPageSegment;
			}

			setState(
				(state) => ({
					...state,
					isDirty: false,
					isSubmitting: false,
				}),
			);

			if (editedSegment === null) {
				pendo.trackEvent('Segment created', {
					'Website ID': websiteId,
					'Segment ID': savedSegment.id,
					'Segment name': savedSegment.label,
				});
			}

			return savedSegment;
		},
		[
			createStaticSegment,
			editedSegment,
			pendo,
			segmentColor,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
			segmentUrls,
			updateStaticSegment,
			websiteId,
		],
	);

	const hasLabel = !!segmentLabel && segmentColor !== null;

	return {
		hasLabel,
		isDirty,
		isSubmitting,
		saveSegment,
		segmentColor,
		segmentIconName,
		segmentLabel,
		segmentShortcode,
		segmentUrls,
		updateIdentifier,
		updateUrls,
		validity,
	};
}



export default useStaticSegmentEditorContext;
