import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage, {
	CalloutMessageSize,
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import ClickableArea from '~/components/patterns/content/ClickableArea';
import NoDataMessage from './NoDataMessage';
import ScoreNumber, {
	ScoreNumberStyle,
} from '~/components/patterns/scores/components/ScoreNumber';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useNumberOfActiveIncidentsWidgetQuery,
} from './NumberOfActiveIncidentsWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useViewportType from '~/hooks/useViewportType';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardNumberOfActiveIncidentsWidget.primaryActionLink',
	},
	titleFull: {
		id: 'ui.dashboardNumberOfActiveIncidentsWidget.title',
	},
	titleShort: {
		id: 'ui.dashboardNumberOfActiveIncidentsWidget.titleShort',
	},
});



const NumberOfActiveIncidentsWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const scopeSection = useDashboardScopeSection();
	const viewportType = useViewportType();
	const websiteId = useWebsiteId();

	const {
		data: metricData,
		loading,
		reasonForNoData,
	} = useDashboardQuery('numberOfActiveIncidents', useNumberOfActiveIncidentsWidgetQuery, {
		variables: {
			scope: scopeSection.scope,
			websiteId,
			...formatDateForQuery(dateRange),
		},
	});

	function renderData({
		numberOfActiveIncidentsRegardlessOfScope,
		numberOfActiveIncidentsWithinScope,
	}): React.ReactNode {
		const noActiveIncidents = (
			numberOfActiveIncidentsWithinScope === 0
			&& numberOfActiveIncidentsRegardlessOfScope === 0
		);

		if (noActiveIncidents) {
			return (
				<ClickableArea
					routeName="website.alerts"
					routeParams={{
						websiteId,
					}}
				>
					<CalloutMessage
						centeredContent={true}
						message="No active incidents"
						size={CalloutMessageSize.Small}
						status={CalloutMessageStatus.Success}
					/>
				</ClickableArea>
			);
		}

		return (
			<SeparatedBlocks
				divider={false}
				gaps={false}
			>
				<ClickableArea
					routeName="website.alerts"
					routeParams={{
						websiteId,
					}}
				>
					<ScoreNumber
						label="this scope"
						style={ScoreNumberStyle.GreyscaleDark}
						value={metricData.numberOfActiveIncidentsWithinScope}
					/>
				</ClickableArea>
				<ClickableArea
					routeName="website.alerts"
					routeParams={{
						websiteId,
					}}
				>
					<ScoreNumber
						label="all scopes"
						style={ScoreNumberStyle.GreyscaleDark}
						value={metricData.numberOfActiveIncidentsRegardlessOfScope}
					/>
				</ClickableArea>
			</SeparatedBlocks>
		);
	}

	return (
		<WidgetCell type={WidgetType.OpenIncidents}>
			<Widget
				label={(
					<FormattedMessage {...messages[viewportType.isSmall ? 'titleShort' : 'titleFull']} />
				)}
				loading={loading}
				overlay={metricData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					routeName: 'website.alerts',
					routeParams: {
						websiteId,
					},
				}}
				showEndDate={true}
			>
				{metricData !== null ? renderData({
					numberOfActiveIncidentsRegardlessOfScope: metricData.numberOfActiveIncidentsRegardlessOfScope,
					numberOfActiveIncidentsWithinScope: metricData.numberOfActiveIncidentsWithinScope,
				}) : null}
			</Widget>
		</WidgetCell>
	);
};



export default NumberOfActiveIncidentsWidget;
