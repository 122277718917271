import {
	LOCALE_EN_US,
} from './locales';



export enum PendoGuide {
	BulkEditAlertDefinitions = 'BulkEditAlertDefinitions',
	Dashboard = 'Dashboard',
	Onboarding = 'Onboarding',
}



const guides = {
	[PendoGuide.BulkEditAlertDefinitions]: {
		[LOCALE_EN_US]: 'Bulk edit alert definitions [EN]',
	},
	[PendoGuide.Dashboard]: {
		[LOCALE_EN_US]: 'Dashboard',
	},
	[PendoGuide.Onboarding]: {
		[LOCALE_EN_US]: 'Onboarding [EN]',
	},
} as const;



export function getPendoGuideName(guide: PendoGuide, locale: string): string | null {
	return guides[guide][locale] ?? null;
}
