import Immutable from 'immutable';
import memoize from 'memoizee';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import AttachedHeading, {
	AttachedHeadingAlignment,
} from '~/components/patterns/structuredValues/AttachedHeading';
import ColumnAddColumn from '~/components/logic/datatables/ColumnAddColumn';
import ColumnAddColumnContent from '~/components/logic/segmentManagement/editor/ColumnAddColumn';
import DatatableContextProvider from '~/components/logic/DatatableContextProvider';
import FilterBox from '~/components/logic/segmentManagement/editor/FilterBox';
import IdentifierBox from '~/components/logic/segmentManagement/editor/IdentifierBox';
import IdentifierModal from '~/components/logic/segmentManagement/editor/IdentifierModal';
import IsWebsiteCrawledContext from '~/components/app/IsWebsiteCrawledContext';
import MarginsList from '~/components/atoms/lists/MarginsList';
import PagesColumnsDatatable from '~/components/app/PagesColumnsDatatable';
import RemoveColumnButton from '~/components/logic/segmentManagement/editor/RemoveColumnButton';
import SaveButton from '~/components/logic/segmentManagement/editor/SaveButton';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';
import SegmentEditorHeader from '~/components/logic/segmentManagement/editor/Header';
import SegmentsSidebarContent from '~/components/patterns/screens/segments/parts/SegmentsSidebarContent';
import SizeLimitBox from '~/components/logic/segmentManagement/editor/SizeLimitBox';
import SizeLimitModal from '~/components/logic/segmentManagement/editor/SizeLimitModal';
import SizeLimitTableFooter from '~/components/logic/segmentManagement/editor/SizeLimitTableFooter';
import Title from '~/components/patterns/headings/Title';

import useDatatableContext from '~/hooks/useDatatableContext';
import useIsIntercomEnabled from '~/hooks/useIsIntercomEnabled';
import useModals from '~/hooks/useModals';
import useProtectUnsavedChanges from '~/hooks/useProtectUnsavedChanges';
import useSegmentEditorContext from '~/hooks/useSegmentEditorContext';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	normalizeFilter,
	removeDefaultFilterValues,
} from '~/model/pages';

import {
	type SegmentDefinition,
	containsCircularReference,
	getSegmentDefinitionByName,
} from '~/model/segments';

import {
	navigate,
} from '~/routing/router';

import decodeFromBase64 from '~/utilities/decodeFromBase64';
import encodeInBase64 from '~/utilities/encodeInBase64';



const messages = defineMessages({
	cannotDependOnDependant: {
		id: 'ui.segments.management.cannotDependOnDependant',
	},
	cannotDependOnSelf: {
		id: 'ui.segments.management.cannotDependOnSelf',
	},
	sortingDisabled: {
		id: 'ui.segments.editor.sortingDisabled',
	},
	title: {
		id: 'ui.segments.editor.sidebar.title',
	},
});



const COLUMN_ADD_COLUMN = 'column_add_column';

const getDefaultFilter = memoize((filterParam) => {
	if (!filterParam) {
		return;
	}

	return Immutable.fromJS(JSON.parse(decodeFromBase64(filterParam)));
});



const getDefaultSort = memoize((sortByParam) => {
	if (!sortByParam) {
		return;
	}

	return Immutable.fromJS(JSON.parse(decodeFromBase64(sortByParam)));
});



const SegmentEditorScreen: React.FC = () => {
	const websiteId = useWebsiteId();

	const datatable = useDatatableContext();
	const isIntercomEnabled = useIsIntercomEnabled();
	const urlState = useUrlState();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);

	const {
		openModal,
	} = useModals();

	const {
		protectUnsavedChanges,
		disableProtection,
	} = useProtectUnsavedChanges();

	const editedSegment = getSegmentDefinitionByName(
		segmentDefinitions.listAll(),
		urlState.params.segmentName ?? '',
	);

	const segmentEditorContext = useSegmentEditorContext({
		defaultFilter: getDefaultFilter(urlState.params.filter),
		defaultSortBy: getDefaultSort(urlState.params.sortBy),
		editedSegment,
		websiteId,
	});

	const [isAddingNewColumn, setAddingNewColumn] = React.useState(false);
	const [addColumnSearch, setColumnSearch] = React.useState('');

	const segmentsNotAllowedForFiltering: Record<string, React.ReactNode> = {};

	if (editedSegment !== null) {
		segmentsNotAllowedForFiltering[editedSegment.name] = (
			<FormattedMessage {...messages.cannotDependOnSelf} />
		);

		segmentDefinitions.listAll().forEach((segment) => {
			if (
				segment.name !== editedSegment.name
				&& containsCircularReference(
					segmentDefinitions.listAll(),
					segment,
					[
						editedSegment.name,
					],
				)
			) {
				segmentsNotAllowedForFiltering[segment.name] = (
					<FormattedMessage {...messages.cannotDependOnDependant} />
				);
			}
		});
	}

	const {
		addColumn,
		columns,
		filter,
		filterDefinition,
		hasLabel,
		isDirty,
		isSubmitting,
		limitedNumberOfPages,
		pages,
		removeColumn,
		removeSizeLimit,
		resetFilter,
		saveSegment,
		segmentColor,
		segmentIconName,
		segmentLabel,
		segmentShortcode,
		sizeLimit,
		sortBy,
		updateFilter,
		updateIdentifier,
		updateSizeLimit,
		updateSortBy,
		validity,
	} = segmentEditorContext;

	React.useEffect(
		() => {
			if (isDirty) {
				protectUnsavedChanges();
			} else {
				disableProtection();
			}
		},
		[
			disableProtection,
			isDirty,
			protectUnsavedChanges,
		],
	);

	const close = React.useCallback(
		(savedSegment?: SegmentDefinition) => {
			let routeName = 'website.pages';
			let routeParams: any = {
				websiteId: urlState.params.websiteId,
			};

			if (urlState.params.back) {
				const back = JSON.parse(decodeFromBase64(urlState.params.back));
				routeName = back.name;
				routeParams = back.params || {};
			}

			if (savedSegment && routeName === 'website.pages') {
				routeParams.filter = encodeInBase64(JSON.stringify(normalizeFilter(Immutable.fromJS({
					segments: {
						included_in: [savedSegment.name],
						not_included_in: [],
						operator: 'and',
					},
				})).toJS()));
			}

			navigate(routeName, routeParams);
		},
		[
			urlState,
		],
	);

	const save = React.useCallback(
		async (input = {}) => {
			const savedSegment = await saveSegment(input);

			disableProtection();
			close(savedSegment);
		},
		[
			close,
			disableProtection,
			saveSegment,
		],
	);

	const openIdentifierModal = React.useCallback(
		(saveAfterSubmit: boolean) => {
			openModal(({ closeCallback }) => {
				async function submitCallback(data) {
					updateIdentifier({
						segmentColor: data.color,
						segmentIconName: data.icon,
						segmentLabel: data.label,
						segmentShortcode: data.shortcode,
					});

					closeCallback();

					if (saveAfterSubmit) {
						save({
							segmentColor: data.color,
							segmentIconName: data.icon,
							segmentLabel: data.label,
							segmentShortcode: data.shortcode,
						});
					}
				}

				return (
					<IdentifierModal
						closeCallback={closeCallback}
						defaultValues={{
							color: segmentColor,
							iconName: segmentIconName,
							label: segmentLabel,
							shortcode: segmentShortcode,
						}}
						editedSegment={editedSegment}
						hasLabel={hasLabel}
						segmentDefinitions={segmentDefinitions.listAll()}
						submitCallback={submitCallback}
					/>
				);
			});
		},
		[
			editedSegment,
			hasLabel,
			openModal,
			save,
			segmentColor,
			segmentDefinitions,
			segmentIconName,
			segmentLabel,
			segmentShortcode,
			updateIdentifier,
		],
	);

	const pagesTotal = pages.total;

	const openSizeLimitModal = React.useCallback(
		() => {
			openModal(({ closeCallback }) => {
				function submitCallback(sizeLimit: CK.PageSegmentSizeLimit) {
					updateSizeLimit(sizeLimit);
					closeCallback();

					if (datatable.scrollToColumn) {
						datatable.scrollToColumn(sizeLimit.field);
					}
				}

				return (
					<SizeLimitModal
						closeCallback={closeCallback}
						sizeLimit={sizeLimit}
						totalNumberOfPages={pagesTotal}
						updateSizeLimit={submitCallback}
					/>
				);
			});
		},
		[
			datatable,
			openModal,
			pagesTotal,
			sizeLimit,
			updateSizeLimit,
		],
	);

	const openAddColumn = React.useCallback(
		() => {
			if (datatable.scrollToColumn) {
				datatable.scrollToColumn(COLUMN_ADD_COLUMN);
			}

			setAddingNewColumn(true);
		},
		[
			datatable,
		],
	);

	const handleOnRequestClose = React.useCallback(
		() => {
			setColumnSearch('');
			setAddingNewColumn(false);
		},
		[],
	);

	const customColumnDefinitions = React.useMemo(
		() => ({
			[COLUMN_ADD_COLUMN]: {
				hasPadding: false,
				name: COLUMN_ADD_COLUMN,
				render: {
					cell: () => null,
					header: ({ columnWidth }) => (
						<ColumnAddColumn
							isOpen={isAddingNewColumn}
							onChangeCallback={({ visible }) => setAddingNewColumn(visible)}
							onClickCallback={openAddColumn}
							onRequestClose={handleOnRequestClose}
							onSearchCallback={setColumnSearch}
							width={columnWidth - 1}
						>
							<ColumnAddColumnContent
								excludedColumns={columns}
								onColumnSelect={(column) => {
									addColumn(column);
									setColumnSearch('');
									setAddingNewColumn(false);
								}}
								searchFilter={addColumnSearch}
							/>
						</ColumnAddColumn>
					),
				},
				width: 300,
			},
		}),
		[
			addColumn,
			addColumnSearch,
			columns,
			handleOnRequestClose,
			isAddingNewColumn,
			openAddColumn,
		],
	);

	const headerAdditionalProps = React.useCallback(
		({ columnName }) => {
			const disabledTooltip = columnName !== CK.PagesCommonColumn.Segments && sizeLimit !== null && (
				<FormattedMessage {...messages.sortingDisabled} />
			);

			if (columnName === CK.PagesCommonColumn.Url) {
				return {
					disabledTooltip,
					isDisabled: sizeLimit !== null,
				};
			}

			return {
				attachedButton: (
					<RemoveColumnButton
						columnName={columnName}
						filterDefinition={filterDefinition}
						onClickCallback={() => {
							removeColumn(columnName);
						}}
						sizeLimit={sizeLimit}
					/>
				),
				disabledTooltip,
				isDisabled: sizeLimit !== null,
			};
		},
		[
			filterDefinition,
			removeColumn,
			sizeLimit,
		],
	);

	const datatableColumns = columns.push(COLUMN_ADD_COLUMN);

	function attemptSave() {
		if (!hasLabel) {
			openIdentifierModal(true);
		} else {
			save();
		}
	}

	const activeRowsLimit = sizeLimit !== null ? limitedNumberOfPages : null;

	const isRowInactive = React.useCallback(
		({ rowIndex }) => {
			if (activeRowsLimit === null) {
				return false;
			}

			return rowIndex >= activeRowsLimit;
		},
		[
			activeRowsLimit,
		],
	);

	return (
		<ScreenLayout
			header={(
				<SegmentEditorHeader
					isNewSegment={editedSegment === null}
					limitedNumberOfPages={limitedNumberOfPages}
					onRequestClose={close}
					segmentLabel={segmentLabel}
				/>
			)}
		>
			<ScreenBodyLayout
				hideContentScrollbar={true}
				hideSidebarScrollbar={true}
				sidebar={(
					<SegmentsSidebarContent
						footer={(
							<SaveButton
								isNewSegment={editedSegment === null}
								isSubmitting={isSubmitting}
								onClickCallback={attemptSave}
								validity={validity}
							/>
						)}
						gapForIntercom={isIntercomEnabled}
					>
						<AttachedHeading
							heading={(
								<Title>
									<FormattedMessage {...messages.title} />
								</Title>
							)}
							headingAlignment={AttachedHeadingAlignment.Left}
						>
							<MarginsList>
								<IdentifierBox
									hasLabel={hasLabel}
									openIdentifierModal={openIdentifierModal}
									segmentColor={segmentColor}
									segmentIconName={segmentIconName}
									segmentLabel={segmentLabel}
									segmentShortcode={segmentShortcode}
								/>

								<FilterBox
									filterDefinition={filterDefinition}
									openAddColumn={openAddColumn}
								/>

								<SizeLimitBox
									hasSizeLimit={sizeLimit !== null}
									limitedNumberOfPages={limitedNumberOfPages}
									openSizeLimitModal={openSizeLimitModal}
									removeSizeLimit={removeSizeLimit}
									sizeLimit={sizeLimit}
									totalNumberOfPages={pages.total}
								/>
							</MarginsList>
						</AttachedHeading>
					</SegmentsSidebarContent>
				)}
				sidebarPosition={ScreenBodyLayoutPosition.Right}
				sidebarWidth={330}
			>
				<IsWebsiteCrawledContext>
					{({ isCrawled }) => (
						<PagesColumnsDatatable
							activeRowsLimit={sizeLimit !== null ? limitedNumberOfPages : null}
							columns={datatableColumns}
							customColumnDefinitions={customColumnDefinitions}
							filter={filter}
							headerPropsProvider={headerAdditionalProps}
							inactiveRowsFooter={sizeLimit !== null && (
								<SizeLimitTableFooter
									inactiveNumberOfPages={pages.total - limitedNumberOfPages}
									onClick={openSizeLimitModal}
								/>
							)}
							isCrawled={isCrawled}
							isDisabled={isAddingNewColumn}
							isLoading={pages.loading}
							isRowClickable={false}
							isRowInactive={isRowInactive}
							onFilterChangeCallback={updateFilter}
							onLoadPagesCallback={pages.fetchFurtherRange}
							onSortChangeCallback={updateSortBy}
							pagesCount={pages.total}
							removeDefaultFilterValues={removeDefaultFilterValues}
							resetFilterAction={resetFilter}
							rowGetter={pages.rowGetter}
							segmentDefinitions={segmentDefinitions.listAll()}
							segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
							sortBy={sortBy}
							websiteId={websiteId}
						/>
					)}
				</IsWebsiteCrawledContext>
			</ScreenBodyLayout>
		</ScreenLayout>
	);
};

const SegmentEditorScreenWrapper: React.FC = () => {
	return (
		<DatatableContextProvider>
			<SegmentEditorScreen />
		</DatatableContextProvider>
	);
};



export default SegmentEditorScreenWrapper;
