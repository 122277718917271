import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AlertTypeTitle from '~/components/logic/alerts/AlertTypeTitle';
import BulkSaveDefinitionsButton from '~/components/logic/alertsConfiguration/BulkSaveDefinitionsButton';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy from '~/components/logic/Copy';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import MessagingAppChannelFieldRow from '~/components/app/MessagingAppChannelFieldRow';
import ModalButtonsLayout, {
	ModalButtonsLayoutType,
} from '~/components/patterns/modals/parts/ModalButtonsLayout';
import ModalContainer from '~/components/atoms/modals/parts/ModalContainer';
import ModalContentWithSidebar from '~/components/atoms/modals/parts/ModalContentWithSidebar';
import ModalHeader, {
	ModalHeaderIconType,
} from '~/components/patterns/modals/parts/ModalHeader';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RecipientsMultiselectField from '~/components/app/RecipientsMultiselectField';
import RichText from '~/components/patterns/typography/RichText';
import SegmentSelectField from '~/components/atoms/forms/components/SegmentSelectField';
import SensitivityField from '~/components/logic/alertsConfiguration/fields/SensitivityField';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useUpdateAlertDefinitionMutation,
} from './EditNativeAlertDefinitionModal.gql';

import useAlertTypeDefinitions from '~/hooks/useAlertTypeDefinitions';
import useCanManageUserAsAlertRecipient from '~/hooks/useCanManageUserAsAlertRecipient';
import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import usePendoGuideInterfaceHint from '~/hooks/usePendoGuideInterfaceHint';
import useSetFlashMessage from '~/hooks/useSetFlashMessage';
import useUglyMicrosoftTeamsMessagingAppChannelOverlay from '~/hooks/useUglyMicrosoftTeamsMessagingAppChannelOverlay';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteSegmentDefinitions from '~/hooks/useWebsiteSegmentDefinitions';

import {
	HINT_PENDO_GUIDE_BULK_EDIT_ALERT_DEFINITIONS,
} from '~/model/interfaceHints';

import {
	PendoGuide,
} from '~/model/pendoGuides';

import {
	ObjectType,
} from '~/model/permissions';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	description: {
		id: 'ui.alertsConfiguration.modal.edit.description',
	},
	fieldRecipients: {
		id: 'ui.alertsConfiguration.fields.recipients',
	},
	fieldScope: {
		id: 'ui.alertsConfiguration.fields.scope',
	},
	fieldSensitivity: {
		id: 'ui.alertsConfiguration.fields.sensitivity',
	},
	headerLabel: {
		id: 'ui.general.settings',
	},
	sidebar: {
		id: 'ui.alertsConfiguration.modal.edit.sidebar',
	},
});



type Props = {
	alertDefinition: GraphQL.AlertDefinition,
};

const EditNativeAlertDefinitionModal: React.FC<Props> = (props) => {
	const {
		alertDefinition,
	} = props;

	const websiteId = useWebsiteId();

	const alertTypeDefinitions = useAlertTypeDefinitions();
	const canManageUserAsAlertRecipient = useCanManageUserAsAlertRecipient(websiteId);
	const classicFormBehavior = useClassicFormBehavior();
	const segmentDefinitions = useWebsiteSegmentDefinitions(websiteId);
	const setFlashMessage = useSetFlashMessage();

	const [updateAlertDefinition] = useUpdateAlertDefinitionMutation();

	const {
		addMicrosoftTeamsMessagingAppChannelOverlay,
		initiateAddMicrosoftTeamsMessagingAppChannel,
		messagingAppChannelFieldRowRef,
	} = useUglyMicrosoftTeamsMessagingAppChannelOverlay();

	const pendoGuideInterfaceHint = usePendoGuideInterfaceHint({
		guide: PendoGuide.BulkEditAlertDefinitions,
		name: HINT_PENDO_GUIDE_BULK_EDIT_ALERT_DEFINITIONS,
	});

	const handleSubmit = React.useCallback(
		async (values) => {
			const recipients = {
				added: values.recipients.filter(canManageUserAsAlertRecipient),
				removed: alertDefinition.recipients
					.filter((recipientEmail) => values.recipients.includes(recipientEmail) === false)
					.filter(canManageUserAsAlertRecipient),
			};

			const messagingAppChannelIds: Array<string> = [];

			if (values.messagingAppChannelId !== 'none') {
				messagingAppChannelIds.push(values.messagingAppChannelId);
			}

			const data: Record<string, any> = {};

			if (values.scope !== undefined) {
				data.scope = values.scope;

				if (data.scope !== 'website') {
					data.scope = 'segment:' + data.scope;
				}
			}

			if (values.sensitivity !== undefined) {
				data.sensitivityLevel = values.sensitivity;
			}

			await updateAlertDefinition({
				variables: {
					alertDefinitionId: alertDefinition.id,
					data,
					messagingAppChannelIds,
					recipients,
					websiteId,
				},
			});

			setTimeout(() => {
				pendoGuideInterfaceHint.showHint();
			}, 200);

			setFlashMessage({
				message: 'alertDefinitionsEdited',
				messageVariables: {
					number__alertDefinitions: 1,
				},
				route: 'website.alerts.configuration',
				style: 'success',
			});

			classicFormBehavior.finish();
		},
		[
			alertDefinition.id,
			alertDefinition.recipients,
			canManageUserAsAlertRecipient,
			classicFormBehavior,
			pendoGuideInterfaceHint,
			setFlashMessage,
			updateAlertDefinition,
			websiteId,
		],
	);

	const alertType = alertTypeDefinitions.getByType(alertDefinition.alertType);

	let currentScope = alertDefinition.scope;

	if (currentScope !== 'website') {
		currentScope = currentScope.replace('segment:', '');
	}

	const messagingAppChannels = alertDefinition.messagingAppChannels;
	const messagingAppChannelId = messagingAppChannels.length > 0
		? getArrayItemAtSafeIndex(messagingAppChannels, 0).id
		: null;

	return (
		<ModalContainer
			gapsSize={0}
			header={(
				<ModalHeader
					iconType={ModalHeaderIconType.CogWheel}
					title={(
						<FormattedMessage {...messages.headerLabel} />
					)}
					titleValue={(
						<AlertTypeTitle
							alertType={alertDefinition.alertType}
							settings={alertDefinition.settings}
						/>
					)}
				/>
			)}
			headerGapsSize={1}
			isLoading={!alertType}
		>
			<DisabledContent
				disabledContent={!!addMicrosoftTeamsMessagingAppChannelOverlay}
				disabledOverlay={addMicrosoftTeamsMessagingAppChannelOverlay}
			>
				<ModalContentWithSidebar
					gapsSize={2}
					sidebar={(
						<RichText>
							<Copy {...messages.sidebar} />
						</RichText>
					)}
				>
					<ModalTextSection>
						<RichText>
							<p>
								<FormattedMessage {...messages.description} />
							</p>
						</RichText>
					</ModalTextSection>

					<Form
						defaultValues={{
							recipients: alertDefinition.recipients,
							scope: currentScope,
							sensitivity: alertDefinition.settings.sensitivityLevel,
							messagingAppChannelId: messagingAppChannelId ?? 'none',
						}}
						onSuccess={handleSubmit}
					>
						{alertType && (
							<CenteredFormWrapper>
								<FormRows>
									{alertType.hasScope && (
										<WithPermission
											action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
											objectId={websiteId}
											objectType={ObjectType.Website}
										>
											{({ isAllowed }) => {
												if (isAllowed.yes === false) {
													return false;
												}

												return (
													<FormRow
														htmlFor="scope"
														label={(
															<FormattedMessage {...messages.fieldScope} />
														)}
													>
														<SegmentSelectField
															inAlertDefinitions={true}
															name="scope"
															segments={segmentDefinitions.listAll()}
															websiteScopeName="website"
														/>
													</FormRow>
												);
											}}
										</WithPermission>
									)}

									{alertType.hasSensitivity && (
										<WithPermission
											action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
											objectId={websiteId}
											objectType={ObjectType.Website}
										>
											{({ isAllowed }) => {
												if (isAllowed.yes === false) {
													return false;
												}

												return (
													<FormRow
														htmlFor="sensitivity"
														label={(
															<FormattedMessage {...messages.fieldSensitivity} />
														)}
													>
														<SensitivityField
															name="sensitivity"
															websiteId={websiteId}
														/>
													</FormRow>
												);
											}}
										</WithPermission>
									)}

									<FormRow
										htmlFor="recipients"
										label={(
											<FormattedMessage {...messages.fieldRecipients} />
										)}
									>
										<RecipientsMultiselectField
											name="recipients"
											websiteId={websiteId}
										/>
									</FormRow>

									<WithPermission
										action={GraphQL.ActionWithWebsite.ManageAlertDefinitions}
										objectId={websiteId}
										objectType={ObjectType.Website}
									>
										{({ isAllowed }) => {
											if (isAllowed.yes === false) {
												return false;
											}

											return (
												<MessagingAppChannelFieldRow
													name="messagingAppChannelId"
													onInitiateAddMicrosoftChannel={initiateAddMicrosoftTeamsMessagingAppChannel}
													ref={messagingAppChannelFieldRowRef}
												/>
											);
										}}
									</WithPermission>
								</FormRows>

								<ModalButtonsLayout type={ModalButtonsLayoutType.FormRow}>
									<CancelButton />

									<BulkSaveDefinitionsButton numberOfAlertDefinitions={1} />
								</ModalButtonsLayout>
							</CenteredFormWrapper>
						)}
					</Form>
				</ModalContentWithSidebar>
			</DisabledContent>
		</ModalContainer>
	);
};



export default EditNativeAlertDefinitionModal;
