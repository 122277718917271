/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminTestFetchViaRenderingModalQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminTestFetchViaRenderingModalQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly userAgentType: string, readonly userAgentValue: string | null, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly onPageRequestsWhitelistRules: ReadonlyArray<{ readonly __typename?: 'OnPageRequestsBlockingRule', readonly operator: GraphQL.OnPageRequestsBlockingRuleOperator, readonly value: string }> } | null } | null };

export type TestFetchViaRenderingQueryVariables = GraphQL.Exact<{
  settings: GraphQL.InputMaybe<GraphQL.TestFetchViaRenderingSettings>;
  url: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type TestFetchViaRenderingQuery = { readonly __typename?: 'Query', readonly testFetchViaRendering: { readonly __typename?: 'TestFetchViaRenderingResult', readonly analyticsDetected: ReadonlyArray<string>, readonly analyticsNotDetected: ReadonlyArray<string>, readonly console: ReadonlyArray<string>, readonly failureReason: GraphQL.FetchingFailureReason | null, readonly totalNumberOfInterceptedRequests: number, readonly interceptedRequestsPerDomain: ReadonlyArray<{ readonly __typename?: 'TestFetchViaRenderingResultDomainInterceptedRequests', readonly domain: string, readonly numberOfRequests: number }>, readonly interceptedRequestsStuckInBrowser: ReadonlyArray<{ readonly __typename?: 'TestFetchViaRenderingResultInterceptedRequestStuckInBrowser', readonly url: string }>, readonly blockedInterceptedRequests: ReadonlyArray<{ readonly __typename?: 'TestFetchViaRenderingResultBlockedInterceptedRequest', readonly url: string, readonly reason: string }>, readonly response: { readonly __typename?: 'TestFetchViaRenderingResultResponse', readonly statusCode: number, readonly httpHeaders: ReadonlyArray<{ readonly __typename?: 'TestFetchViaRenderingResultResponseHttpHeaderValues', readonly headerName: string, readonly values: ReadonlyArray<string> }> } | null } | null };


export const WebsiteAdminTestFetchViaRenderingModalDocument = gql`
    query WebsiteAdminTestFetchViaRenderingModal($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    userAgentType
    userAgentValue
    adminSettings {
      onPageRequestsWhitelistRules {
        operator
        value
      }
    }
  }
}
    `;

/**
 * __useWebsiteAdminTestFetchViaRenderingModalQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminTestFetchViaRenderingModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminTestFetchViaRenderingModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminTestFetchViaRenderingModalQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminTestFetchViaRenderingModalQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables> & ({ variables: WebsiteAdminTestFetchViaRenderingModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables>(WebsiteAdminTestFetchViaRenderingModalDocument, options);
      }
export function useWebsiteAdminTestFetchViaRenderingModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables>(WebsiteAdminTestFetchViaRenderingModalDocument, options);
        }
export function useWebsiteAdminTestFetchViaRenderingModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables>(WebsiteAdminTestFetchViaRenderingModalDocument, options);
        }
export type WebsiteAdminTestFetchViaRenderingModalQueryHookResult = ReturnType<typeof useWebsiteAdminTestFetchViaRenderingModalQuery>;
export type WebsiteAdminTestFetchViaRenderingModalLazyQueryHookResult = ReturnType<typeof useWebsiteAdminTestFetchViaRenderingModalLazyQuery>;
export type WebsiteAdminTestFetchViaRenderingModalSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminTestFetchViaRenderingModalSuspenseQuery>;
export type WebsiteAdminTestFetchViaRenderingModalQueryResult = Apollo.QueryResult<WebsiteAdminTestFetchViaRenderingModalQuery, WebsiteAdminTestFetchViaRenderingModalQueryVariables>;
export const TestFetchViaRenderingDocument = gql`
    query TestFetchViaRendering($settings: TestFetchViaRenderingSettings, $url: String!, $websiteId: WebsiteId!) {
  testFetchViaRendering(settings: $settings, url: $url, websiteId: $websiteId) {
    analyticsDetected
    analyticsNotDetected
    interceptedRequestsPerDomain {
      domain
      numberOfRequests
    }
    interceptedRequestsStuckInBrowser {
      url
    }
    blockedInterceptedRequests {
      url
      reason
    }
    console
    failureReason
    response {
      httpHeaders {
        headerName
        values
      }
      statusCode
    }
    totalNumberOfInterceptedRequests
  }
}
    `;

/**
 * __useTestFetchViaRenderingQuery__
 *
 * To run a query within a React component, call `useTestFetchViaRenderingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestFetchViaRenderingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestFetchViaRenderingQuery({
 *   variables: {
 *      settings: // value for 'settings'
 *      url: // value for 'url'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useTestFetchViaRenderingQuery(baseOptions: Apollo.QueryHookOptions<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables> & ({ variables: TestFetchViaRenderingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables>(TestFetchViaRenderingDocument, options);
      }
export function useTestFetchViaRenderingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables>(TestFetchViaRenderingDocument, options);
        }
export function useTestFetchViaRenderingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables>(TestFetchViaRenderingDocument, options);
        }
export type TestFetchViaRenderingQueryHookResult = ReturnType<typeof useTestFetchViaRenderingQuery>;
export type TestFetchViaRenderingLazyQueryHookResult = ReturnType<typeof useTestFetchViaRenderingLazyQuery>;
export type TestFetchViaRenderingSuspenseQueryHookResult = ReturnType<typeof useTestFetchViaRenderingSuspenseQuery>;
export type TestFetchViaRenderingQueryResult = Apollo.QueryResult<TestFetchViaRenderingQuery, TestFetchViaRenderingQueryVariables>;