import React from 'react';
import {
	useIntl,
} from 'react-intl';

import AbbreviatedNumber from '~/components/app/AbbreviatedNumber';
import AttachedChartLegend, {
	AttachedChartLegendAlignment,
} from '~/components/patterns/charts/structures/AttachedChartLegend';
import ChartContainer, {
	CHART_HEIGHT,
} from '~/components/atoms/charts/components/ChartContainer';
import ChartLegend from '~/components/patterns/charts/components/ChartLegend';
import DonutChart from '~/components/atoms/charts/charts/DonutChart';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';

import useViewportType from '~/hooks/useViewportType';



const defaultRandomColors = [];
const defaultSpecificColors = {};



type Props = {
	data: any,
	height?: number,
	labels?: Record<string, string>,
	name: string,
	order?: ReadonlyArray<string>,
	percentageFactor?: number,
	randomColors?: ReadonlyArray<string>,
	specificColors?: Record<string, string>,
};

const DonutPagesChart: React.FC<Props> = (props) => {
	const {
		data,
		height,
		labels,
		name,
		order,
		percentageFactor = 1,
		randomColors = defaultRandomColors,
		specificColors = defaultSpecificColors,
	} = props;

	const intl = useIntl();
	const viewportType = useViewportType();

	function renderLegend() {
		const items: Array<{
			color: string | undefined,
			count: number,
			countDisplay: React.ReactNode,
			label: string,
		}> = [];

		let usedSpecificColors = 0;

		if (order) {
			order.forEach((key) => {
				const count = data
					? data.find((item) => item.get('value') === key).get('count')
					: null;

				const countDisplay = count !== null && (
					<AbbreviatedNumber value={count} />
				);

				items.push({
					color: specificColors[key],
					count,
					countDisplay,
					label: labels?.[key] ?? key,
				});
			});
		} else if (data) {
			data.forEach((item, index: number) => {
				if (specificColors[item.get('value')] !== undefined) {
					usedSpecificColors++;
				}

				const count = item.get('count');

				const countDisplay = count !== null && (
					<AbbreviatedNumber value={count} />
				);

				items.push({
					color: specificColors[item.get('value')] || randomColors[index - usedSpecificColors] || 'grey',
					count,
					countDisplay,
					label: labels?.[item.get('value')] ?? item.get('value'),
				});
			});
		}

		return (
			<ChartLegend
				items={items}
			/>
		);
	}

	const renderTooltip = React.useCallback(
		({ count, key }) => {
			const value = typeof data.first().get('value') === 'boolean'
				? (key === 'true')
				: key;

			const label = (labels && labels[value])
				? labels[value]
				: key;

			const percentage = intl.formatNumber(
				data.find((item) => item.get('value') === value).get('percentage') / percentageFactor,
				{
					style: 'percent',
					maximumFractionDigits: 2,
					maximumSignificantDigits: 4,
				},
			);

			return label + ': ' + intl.formatNumber(count) + ' (' + percentage + ')';
		},
		[
			data,
			intl,
			labels,
			percentageFactor,
		],
	);

	let renderableData: any = data;

	if (renderableData) {
		renderableData = renderableData.filter((item) => {
			return item.get('count') > 0;
		});

		renderableData = order
			? renderableData.sort((itemA, itemB) => {
				const positionA = order.indexOf(itemA.get('value'));
				const positionB = order.indexOf(itemB.get('value'));

				if (positionA === positionB) {
					return 0;
				}

				return positionA < positionB ? -1 : 1;
			})
			: renderableData;
	}

	let usedSpecificColors = 0;

	return (
		<AttachedChartLegend
			legend={renderLegend()}
			legendAlignment={AttachedChartLegendAlignment.Right}
		>
			{renderableData ? (
				<ChartContainer
					chart={(
						<DonutChart
							animate={true}
							data={[
								{
									data: renderableData.map((item, index) => {
										if (specificColors[item.get('value')] !== undefined) {
											usedSpecificColors++;
										}

										return {
											name: typeof item.get('value') === 'boolean'
												? (item.get('value') ? 'true' : 'false')
												: item.get('value'),
											y: item.get('count'),
											color: specificColors[item.get('value')] || randomColors[index - usedSpecificColors] || 'grey',
										};
									}).toJS(),
									id: 'types',
									name: 'Types',
									tooltip: {
										followPointer: false,
									},
								},
							]}
							key={name}
							tooltipFormatter={renderTooltip}
							viewportType={viewportType}
						/>
					)}
					height={height}
					name={name}
					type="donut-chart"
				/>
			) : (
				<SquareSkeleton
					height={CHART_HEIGHT}
					style={SquareSkeletonStyle.Transparent}
				/>
			)}
		</AttachedChartLegend>
	);
};



export default React.memo(DonutPagesChart);
