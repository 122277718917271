import Immutable from 'immutable';
import React from 'react';
import {
	useStore,
} from 'react-redux';
import type CK from '~/types/contentking';

import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	CHANGES_IN_ALL_SELECTED_COLUMNS,
	CHANGES_IN_ANY_COLUMN,
	CHANGES_IN_ANY_SELECTED_COLUMN,
	CHANGES_IN_ANY_VISIBLE_COLUMN,
	CHANGES_IN_OPERATOR_AND,
} from '~/model/historicalChanges';

import {
	ColumnsWithTrackedChangesScope,
} from '~/model/pagesColumns';

import {
	filterSelector,
} from '~/state/historicalChanges/selectors';



function useBuildTrackedChangesFilterDefinition() {
	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enabledWebsiteColumnsList = useEnabledWebsiteColumnsList();
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const store = useStore();

	return React.useCallback(
		() => {
			let result = filterSelector(store.getState());
			let changesIn: any = Immutable.List();

			const applicableColumns = ColumnsWithTrackedChangesScope.concat(
				customElementDefinitions
					.listAll()
					.map((customElementDefinition) => customElementDefinition.column),
			).concat(
				enrichmentFieldDefinitions
					.listAll()
					.map((enrichmentFieldDefinition) => enrichmentFieldDefinition.column),
			);

			if (result.get('changes_in') === CHANGES_IN_ANY_VISIBLE_COLUMN) {
				changesIn = enabledWebsiteColumnsList
					.filter((column: CK.PagesColumn) => applicableColumns.includes(column))
					.toList();
			}

			if (result.get('changes_in') === CHANGES_IN_ANY_COLUMN) {
				changesIn = Immutable.List(applicableColumns);
			}

			if (result.get('changes_in') === CHANGES_IN_ANY_SELECTED_COLUMN) {
				changesIn = result.get('changes_in_columns');

				result = result.remove('changes_in_columns');
			}

			if (result.get('changes_in') === CHANGES_IN_ALL_SELECTED_COLUMNS) {
				changesIn = result.get('changes_in_columns');

				result = result.set('changes_in_operator', CHANGES_IN_OPERATOR_AND);
				result = result.remove('changes_in_columns');
			}

			if (changesIn && changesIn.size > 0) {
				result = result.set('changes_in', changesIn);
			} else {
				result = result.remove('changes_in');
			}

			return result;
		},
		[
			customElementDefinitions,
			enabledWebsiteColumnsList,
			enrichmentFieldDefinitions,
			store,
		],
	);
}



export default useBuildTrackedChangesFilterDefinition;
