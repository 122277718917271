import Immutable from 'immutable';
import React from 'react';

import type CK from '~/types/contentking';

import CalloutMessage, {
	CalloutMessageSize,
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FilterDefinitionFormatter, {
	FilterDefinitionFormatterStyle,
} from '~/components/logic/filters/FilterDefinitionFormatter';
import ItemPerLineTextArea from '~/components/app/ItemPerLineTextArea';
import ModalButtonsLayout from '~/components/patterns/modals/parts/ModalButtonsLayout';
import RadioList from '~/components/atoms/forms/components/RadioList';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';

import {
	validateField,
} from '~/components/app/validations';

import validateUrls from '~/components/validations/validateUrls';

import {
	useDeleteCustomUrlsMutation,
	useDeleteUrlsByFilterMutation,
} from './DeleteUrlModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useCountPages from '~/hooks/useCountPages';

import {
	getFilter,
	removeDefaultFilterValues,
} from '~/model/pages';

import decodeFromBase64 from '~/utilities/decodeFromBase64';



enum Target {
	Filter = 'filter',
	UrlLines = 'urlLines',
}

const validations = {
	validatePagesOverviewUrl: validateField(
		'pagesOverviewUrl',
		(f) => [
			f.whenOtherField(
				'target',
				({ value }) => value === Target.Filter,
			),
			f.validateNonEmpty(),
		],
	),
	validateUrlLines: validateField(
		'urlLines',
		(f) => [
			f.whenOtherField(
				'target',
				({ value }) => value === Target.UrlLines,
			),
			...validateUrls(f, {
				minimumAmount: 1,
			}),
		],
	),
};



type Props = {
	websiteId: CK.WebsiteId,
};

const DeleteUrlModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const [deleteCustomUrls] = useDeleteCustomUrlsMutation();
	const [deleteUrlsByFilter] = useDeleteUrlsByFilterMutation();

	const [filter, setFilter] = React.useState<any>(null);

	const numberOfPagesToBeDeleted = useCountPages({
		filter: filter ?? [],
		skip: filter === null,
		pollIntervalInMilliseconds: 0,
		websiteId,
	});

	const handleChange = React.useCallback(
		(field, value) => {
			if (field !== 'pagesOverviewUrl') {
				return;
			}

			try {
				const url = new URL(value);
				const filterString = url.searchParams.get('filter') ?? '';

				const filter = removeDefaultFilterValues(
					Immutable.fromJS(JSON.parse(
						decodeFromBase64(filterString),
					).filter),
				).toJS();

				setFilter(filter);
			} catch (error) {
				setFilter(null);
				return;
			}
		},
		[],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			if (values.target === Target.UrlLines) {
				await deleteCustomUrls({
					variables: {
						urls: values.urlLines,
						websiteId,
					},
				});
			} else {
				const {
					criteria,
				} = getFilter(Immutable.fromJS(filter));

				await deleteUrlsByFilter({
					variables: {
						cleanupRobotsTxtRevisions: false,
						filter: criteria,
						websiteId,
					},
				});
			}

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			deleteCustomUrls,
			deleteUrlsByFilter,
			filter,
			websiteId,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Large}
			title="Delete URL"
		>
			<Form
				defaultFocus="urlLines"
				defaultValues={{
					target: Target.UrlLines,
					urlLines: [],
				}}
				onChangeCallback={handleChange}
				onSuccess={handleSubmit}
				validations={validations}
			>
				{({ values }) => (
					<>
						<FormRows>
							<FormRow
								label="Target method"
							>
								<RadioList
									inline={true}
									items={[
										{
											label: 'specific URLs',
											value: Target.UrlLines,
										},
										{
											label: 'by filter',
											value: Target.Filter,
										},
									]}
									name="target"
									width={false}
								/>
							</FormRow>
						</FormRows>

						<br />

						{values.target === Target.UrlLines && (
							<FormRows>
								<FormRow
									description={(
										<>
											Place one URL per line. You can use only absolute URLs.
										</>
									)}
									fullwidth={true}
									htmlFor="urlLines"
									label="URLs"
								>
									<FieldStatus name="validateUrlLines">
										<ItemPerLineTextArea
											name="urlLines"
											size={{
												rows: 8,
												width: '100%',
											}}
										/>
									</FieldStatus>
								</FormRow>

								<FormRow label="Number of pages">
									<StaticText>
										{values.urlLines?.length ?? 0}
									</StaticText>
								</FormRow>
							</FormRows>
						)}

						{values.target === Target.Filter && (
							<FormRows>
								<FormRow
									description={(
										<>
											Filter the pages overview to show only the pages which need to be deleted and copy/paste the url into this form.
										</>
									)}
									fullwidth={true}
									htmlFor="pagesOverviewUrl"
									label="Pages URL"
								>
									<FieldStatus name="validatePagesOverviewUrl">
										<TextArea
											name="pagesOverviewUrl"
											rows={2}
											width="100%"
										/>
									</FieldStatus>
								</FormRow>

								<FormRow label="Number of pages">
									<StaticText>
										{numberOfPagesToBeDeleted.total}
									</StaticText>
								</FormRow>

								<FormRow
									fullwidth={true}
									label="Filter"
								>
									<StaticText width="100%">
										{filter !== null && (
											<FilterDefinitionFormatter
												filterDefinition={filter}
												style={FilterDefinitionFormatterStyle.CompactList}
											/>
										)}
									</StaticText>
								</FormRow>
							</FormRows>
						)}

						<CalloutMessage
							borders={true}
							message="This tool deletes only already crawled URLs. URLs that have been found but not crawled yet won't be deleted."
							size={CalloutMessageSize.Small}
							status={CalloutMessageStatus.Normal}
						/>

						<ModalButtonsLayout>
							<CancelButton />

							<SubmitButton>
								Delete
							</SubmitButton>
						</ModalButtonsLayout>
					</>
				)}
			</Form>
		</SimpleModal>
	);
};



export default DeleteUrlModal;
