import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import AppUpdated from '~/components/app/AppUpdated';
import CurrentWebsiteIdProvider from '~/components/providers/CurrentWebsiteIdProvider';
import DataProcessingAgreementModalPrompt from '~/components/app/DataProcessingAgreementModalPrompt';
import DynamicModal, {
	DynamicModalRenderer,
} from '~/components/logic/DynamicModal';
import ExternalActions from '~/components/app/ExternalActions';
import ForcedModals from '~/components/app/ForcedModals';
import Intercom from '~/components/atoms/Intercom';
import IntercomManager from '~/components/atoms/IntercomManager';
import PageLayout from '~/components/patterns/layout/PageLayout';
import PanelStack from '~/components/atoms/panels/PanelStack';
import PremiumTrialBar from '~/components/logic/PremiumTrialBar';
import RefreshOnLocaleChange from '~/components/atoms/RefreshOnLocaleChange';
import RouterTransitions from '~/components/app/RouterTransitions';
import ScreenSidebar from '~/components/logic/layouts/screens/ScreenSidebar';
import Timeout from '~/utilities/Timeout';
import TrialBar from '~/components/logic/TrialBar';

import useAccountActivePremiumTrial from '~/hooks/useAccountActivePremiumTrial';
import useAccountActiveTrial from '~/hooks/useAccountActiveTrial';
import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useAllWebsites from '~/hooks/useAllWebsites';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useIsIntercomEnabled from '~/hooks/useIsIntercomEnabled';
import useNavigate from '~/hooks/useNavigate';
import useUrlState from '~/hooks/useUrlState';

import {
	markAppEmptyState,
} from '~/actions';

import {
	synchronizeTeams,
} from '~/actions/teams';

import {
	tryLoadUserData,
} from '~/model/authentication';

import {
	EMPTY_STATE_NO_WEBSITES,
} from '~/model/ui/appEmpty';

import {
	SYNC_INTERVAL,
} from '~/model/websites';

import {
	appEmptyStateSelector,
} from '~/state/ui/selectors';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const AuthenticatedSection: React.FC = () => {
	const accountId = useAccountId();
	const homeAccountId = useEffectiveHomeAccountId();

	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);
	const allWebsites = useAllWebsites();
	const appEmptyState = useSelector(appEmptyStateSelector);
	const dispatch = useDispatch();
	const homeAccountActivePremiumTrial = useAccountActivePremiumTrial(homeAccountId);
	const homeAccountActiveTrial = useAccountActiveTrial(homeAccountId);
	const isIntercomEnabled = useIsIntercomEnabled();
	const navigate = useNavigate();
	const urlState = useUrlState();

	React.useEffect(
		() => {
			const synchronizeTeamsTimeout = new Timeout(
				() => dispatch(synchronizeTeams())
					.catch(() => { })
					.then(() => synchronizeTeamsTimeout.start()),
				SYNC_INTERVAL,
			);

			const synchronizeUserDataTimeout = new Timeout(
				() => dispatch(tryLoadUserData())
					.catch(() => { })
					.then(() => synchronizeUserDataTimeout.start()),
				SYNC_INTERVAL,
			);

			synchronizeTeamsTimeout.start(true);
			synchronizeUserDataTimeout.start(true);

			return () => {
				synchronizeTeamsTimeout.stop();
				synchronizeUserDataTimeout.stop();
			};
		},
		[
			dispatch,
		],
	);

	const isFirstWebsiteIdReady = allWebsites.isLoaded;
	const firstWebsiteId = allWebsites.listAll().length > 0
		? getArrayItemAtSafeIndex(allWebsites.listAll(), 0)
		: null;
	const websiteIdParam = urlState.params.websiteId;

	React.useEffect(
		() => {
			if (websiteIdParam === '{id}' && isFirstWebsiteIdReady) {
				if (firstWebsiteId !== null) {
					navigate({
						keepParameters: true,
						options: {
							replace: true,
						},
						routeName: urlState.name,
						routeParams: {
							websiteId: firstWebsiteId,
						},
					});
				} else {
					navigate({
						routeName: urlState.name.indexOf('account.') === 0
							? 'account.websites.new'
							: 'empty_state',
						options: {
							replace: true,
						},
					});
				}
			}

			if (
				accountPhase === GraphQL.AccountPhase.Trial
				&& accountState === GraphQL.AccountState.Expired
				&& urlState.name.indexOf('website.alerts.configuration') === 0
			) {
				navigate({
					routeName: 'userProfile',
				});
			}
		},
		[
			accountPhase,
			accountState,
			firstWebsiteId,
			isFirstWebsiteIdReady,
			navigate,
			urlState.name,
			websiteIdParam,
		],
	);

	const numberOfWebsites = allWebsites.isLoaded
		? allWebsites.listAll().length
		: null;

	React.useEffect(
		() => {
			if (numberOfWebsites === null) {
				return;
			}

			const newAppEmptyState = numberOfWebsites > 0
				? false
				: EMPTY_STATE_NO_WEBSITES;

			if (appEmptyState !== newAppEmptyState) {
				dispatch(
					markAppEmptyState(newAppEmptyState),
				);

				if (urlState.name.split('.')[0] !== 'account') {
					navigate({
						routeName: 'empty_state',
					});
				}
			}
		},
		[
			appEmptyState,
			dispatch,
			navigate,
			numberOfWebsites,
			urlState.name,
		],
	);

	let trialBar;

	if (homeAccountId !== null) {
		if (
			homeAccountActiveTrial !== null
			&& homeAccountActiveTrial.isTrialBarDisplayed
			&& urlState.name.startsWith('account.pricing') === false
			&& urlState.name.startsWith('website.segmentEditor') === false
		) {
			trialBar = (
				<TrialBar
					accountId={homeAccountId}
					expirationDate={homeAccountActiveTrial.expirationDate}
				/>
			);
		} else if (
			homeAccountActivePremiumTrial !== null
			&& urlState.name.startsWith('website.segmentEditor') === false
		) {
			trialBar = (
				<PremiumTrialBar
					accountId={homeAccountId}
					expirationDate={homeAccountActivePremiumTrial.expirationDate}
				/>
			);
		}
	}

	return (
		<>
			<RouterTransitions />
			<RefreshOnLocaleChange />
			<Intercom />
			<IntercomManager />
			<ExternalActions />
			<DataProcessingAgreementModalPrompt />
			<PageLayout
				appAnnouncement={(
					<AppUpdated />
				)}
				gapForIntercom={isIntercomEnabled}
				sidebar={(
					<ScreenSidebar />
				)}
				trialBar={trialBar}
			>
				<PanelStack />
				<DynamicModalRenderer />
				<ForcedModals />
			</PageLayout>
		</>
	);
};

class AuthenticatedSectionClass extends React.Component {

	render() {
		return (
			<DynamicModal>
				<CurrentWebsiteIdProvider>
					<AuthenticatedSection />
				</CurrentWebsiteIdProvider>
			</DynamicModal>
		);
	}

}



export default AuthenticatedSectionClass;
