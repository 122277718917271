import React from 'react';

import {
	useVirtualizer,
} from '@tanstack/react-virtual';

import MarginBox, {
	SIZE_LARGE as MARGIN_BOX_SIZE_LARGE,
} from '~/components/atoms/boxes/MarginBox';
import ScopeSection from './ScopeSection';

import useScrollContainer from '~/hooks/useScrollContainer';
import useViewportType from '~/hooks/useViewportType';

import {
	type Scope,
} from '~/model/scopes';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



type Props = {
	scopes: Array<Scope>,
};

const ScopesList: React.FC<Props> = (props) => {
	const {
		scopes,
	} = props;

	const scrollContainer = useScrollContainer();
	const viewportType = useViewportType();

	const rowVirtualizer = useVirtualizer({
		count: scopes.length,
		getScrollElement: () => scrollContainer,
		estimateSize: () => {
			if (viewportType.isExtraLarge || viewportType.isLarge) {
				return 629;
			} else if (viewportType.isMedium) {
				return 1175;
			} else if (viewportType.isSmall) {
				return 1320;
			}

			return 1000;
		},
		overscan: 2,
	});

	React.useEffect(
		() => {
			rowVirtualizer.measure();
		},
		[
			rowVirtualizer,
		],
	);


	return (
		<div
			style={{
				height: `${rowVirtualizer.getTotalSize()}px`,
				width: '100%',
				position: 'relative',
			}}
		>
			{rowVirtualizer.getVirtualItems().map((virtualRow) => (
				<div
					data-index={virtualRow.index}
					key={getArrayItemAtSafeIndex(scopes, virtualRow.index)}
					ref={rowVirtualizer.measureElement}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						transform: `translateY(${virtualRow.start}px)`,
					}}
				>
					<MarginBox size={MARGIN_BOX_SIZE_LARGE}>
						<ScopeSection
							scope={getArrayItemAtSafeIndex(scopes, virtualRow.index)}
							suspendDataFetching={rowVirtualizer.isScrolling}
						/>
					</MarginBox>
				</div>
			))}
		</div>
	);
};

export default ScopesList;
