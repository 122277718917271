import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import BooleanChart from '~/components/logic/pagesOverview/pagesCharts/fields/BooleanChart';
import NoDataMessage from './NoDataMessage';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useDistributionOfIsIndexableWidgetQuery,
} from './DistributionOfIsIndexableWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToPages from '~/hooks/useNavigateToPages';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardDistributionOfIsIndexableWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardDistributionOfIsIndexableWidget.title',
	},
});



const DistributionOfIsIndexableWidget: React.FC = () => {
	const dateRange = useDashboardDateRange();
	const navigateToPages = useNavigateToPages();
	const scopeSection = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data,
		loading,
		reasonForNoData,
	} = useDashboardQuery('distributionOfIsIndexable', useDistributionOfIsIndexableWidgetQuery, {
		variables: {
			scope: scopeSection.scope,
			websiteId,
			...formatDateForQuery(dateRange),
		},
	});

	const chartData = React.useMemo(
		() => {
			if (data === null) {
				return null;
			}

			return data.map((item) => ({
				count: item.numberOfPages,
				percentage: item.percentageOfPages,
				value: item.state,
			}));
		},
		[
			data,
		],
	);

	const hasData = (
		chartData !== null
		&& chartData.reduce((total, { count }) => total + count, 0) > 0
	);

	return (
		<WidgetCell type={WidgetType.Indexable}>
			<Widget
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={!hasData && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					...navigateToPages({
						graphs: true,
						scope: scopeSection.scope,
					}),
				}}
				showEndDate={true}
			>
				{hasData && (
					<BooleanChart
						data={chartData}
						falseColor="#ff743b"
						field="is_indexable"
						height={140}
						percentageFactor={1}
					/>
				)}
			</Widget>
		</WidgetCell>
	);
};



export default DistributionOfIsIndexableWidget;
