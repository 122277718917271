import React from 'react';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SwitchField from '~/components/app/SwitchField';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useRetryFailedJobsMutation,
} from './AdminRetryFailedJobsModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';



const validations = {
	validateJobQueue: validateField(
		'jobQueue',
		(f) => [
			f.validateNonEmpty(),
		],
	),
};



const AdminRetryFailedJobsModal: React.FC = () => {
	const classicFormBehavior = useClassicFormBehavior();

	const [retryFailedJobs] = useRetryFailedJobsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await retryFailedJobs({
				variables: {
					jobQueue: values.jobQueue,
					resetProgress: values.resetProgress,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			retryFailedJobs,
		],
	);

	return (
		<SimpleModal
			size={SimpleModalSize.Small}
			title="Retry failed jobs"
		>
			<Form
				defaultFocus="jobQueue"
				defaultValues={{
					jobQueue: '',
					resetProgress: false,
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRow
					htmlFor="jobQueue"
					label="Job queue"
				>
					<FieldStatus name="validateJobQueue">
						<TextField
							name="jobQueue"
						/>
					</FieldStatus>
				</FormRow>

				<FormRow
					htmlFor="resetProgress"
					label="Reset progress"
				>
					<SwitchField
						activeStateLabel="yes"
						inactiveStateLabel="no"
						name="resetProgress"
					/>
				</FormRow>

				<ButtonsLayout>
					<CancelButton />

					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default AdminRetryFailedJobsModal;
