import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BlankValue from '~/components/patterns/values/BlankValue';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import FormFieldsDecorator from '~/components/patterns/forms/others/FormFieldsDecorator';
import MultiselectField from '~/components/atoms/forms/components/MultiselectField';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextField, {
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';
import WebsiteAdminFetchingSettingDomSizeLimitFieldRow from '~/components/app/WebsiteAdminFetchingSettingDomSizeLimitFieldRow';
import WebsiteAdminFetchingSettingDomStabilizationPeriodFieldRow from '~/components/app/WebsiteAdminFetchingSettingDomStabilizationPeriodFieldRow';
import WebsiteAdminFetchingSettingMainRequestSizeLimitFieldRow from '~/components/app/WebsiteAdminFetchingSettingMainRequestSizeLimitFieldRow';
import WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow from '~/components/app/WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow';
import WebsiteAdminFetchingSettingNavigationTimeoutFieldRow from '~/components/app/WebsiteAdminFetchingSettingNavigationTimeoutFieldRow';
import WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow from '~/components/app/WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow';
import WebsiteAdminFetchingSettingOnPageRequestsLimitNormalTotalFieldRow from '~/components/app/WebsiteAdminFetchingSettingOnPageRequestsLimitNormalTotalFieldRow';

import {
	useUpdateWebsiteAdminFetchingSettingsMutation,
	useWebsiteAdminFetchingSettingsFormQuery,
} from './WebsiteAdminFetchingSettingsForm.gql';

import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useWebsiteIsDomTracked from '~/hooks/useWebsiteIsDomTracked';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';

import getOptionsLabel from '~/utilities/getOptionsLabel';



function getIpPoolsOptions(currentValues: ReadonlyArray<string> | null = null) {
	const options = [
		{
			name: 'primary',
			title: 'Primary IP pool',
		},
		{
			name: 'secondary',
			title: 'Secondary IP pool',
		},
		{
			name: 'tertiary',
			title: 'Tertiary IP pool',
		},
	];

	if (currentValues !== null) {
		options.map(({ name, title }) => {
			return {
				disabled: (
					currentValues.length === 1
					&& currentValues.includes(name)
				),
				name,
				title,
			};
		});
	}

	return options;
}



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminFetchingSettingsForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const isDomTracked = useWebsiteIsDomTracked(websiteId);
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);

	const isAllowedToManageAdminFetchingSettings = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageAdminFetchingSettings,
	);

	const { data } = useWebsiteAdminFetchingSettingsFormQuery({
		variables: {
			websiteId,
		},
	});

	const [updateWebsiteAdminFetchingSettings] = useUpdateWebsiteAdminFetchingSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteAdminFetchingSettings({
				variables: {
					domSizeLimit: parseInt(values.domSizeLimit) || null,
					domStabilizationPeriod: values.domStabilizationPeriod !== ''
						? parseInt(values.domStabilizationPeriod)
						: null,
					ipPools: values.ipPools,
					lighthouseChromiumResourceCacheSizeLimit: parseInt(values.lighthouseChromiumResourceCacheSizeLimit) || null,
					lighthouseFcpTimeout: parseInt(values.lighthouseFcpTimeout) || null,
					mainRequestSizeLimit: parseInt(values.mainRequestSizeLimit) || null,
					mainRequestTimeout: parseInt(values.mainRequestTimeout) || null,
					navigationTimeout: parseInt(values.navigationTimeout) || null,
					onPageRequestsLimitLighthouseTotal: parseInt(values.onPageRequestsLimitLighthouseTotal) || null,
					onPageRequestsLimitLighthousePerDomain: parseInt(values.onPageRequestsLimitLighthousePerDomain) || null,
					onPageRequestsLimitNormalTotal: parseInt(values.onPageRequestsLimitNormalTotal) || null,
					onPageRequestsLimitNormalPerDomain: parseInt(values.onPageRequestsLimitNormalPerDomain) || null,
					websiteId,
				},
			});
		},
		[
			updateWebsiteAdminFetchingSettings,
			websiteId,
		],
	);

	const adminSettings = data?.website?.adminSettings ?? null;

	const ipPoolsOptions = React.useMemo(
		() => {
			return getIpPoolsOptions(adminSettings?.ipPools ?? []);
		},
		[
			adminSettings?.ipPools,
		],
	);

	const autoLabel = (
		<BlankValue>auto</BlankValue>
	);

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageAdminFetchingSettings}
			isForAdmins={true}
			title="Fetching settings"
		>
			<DisplayPart>
				<FormRows>
					{!isDomTracked && !isLighthouseMonitored && (
						<FormRow label="IP pools">
							<StaticList focusTarget="ipPools">
								{getOptionsLabel(getIpPoolsOptions(), adminSettings?.ipPools ?? [])}
							</StaticList>
						</FormRow>
					)}

					<FormRow
						description="auto: 1048576 b (1 MB)"
						label="Size limit"
					>
						<StaticText focusTarget="mainRequestSizeLimit">
							{adminSettings?.mainRequestSizeLimit ?? autoLabel}

							{adminSettings?.mainRequestSizeLimit && ' b'}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 10000 ms (10 s)"
						label="Time-out"
					>
						<StaticText focusTarget="mainRequestTimeout">
							{adminSettings?.mainRequestTimeout ?? autoLabel}

							{adminSettings?.mainRequestTimeout && ' ms'}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 10000 ms (10 s)"
						label="Navigation Timeout"
					>
						<StaticText focusTarget="navigationTimeout">
							{adminSettings?.navigationTimeout ?? autoLabel}

							{adminSettings?.navigationTimeout && ' ms'}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 100"
						label="Max. on-page requests in total"
					>
						<StaticText focusTarget="onPageRequestsLimitNormalTotal">
							{adminSettings?.onPageRequestsLimitNormalTotal ?? autoLabel}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 100"
						label="Max. on-page requests per domain"
					>
						<StaticText focusTarget="onPageRequestsLimitNormalPerDomain">
							{adminSettings?.onPageRequestsLimitNormalPerDomain ?? autoLabel}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 500"
						label="Max. on-page requests in total with Lighthouse"
					>
						<StaticText focusTarget="onPageRequestsLimitLighthouseTotal">
							{adminSettings?.onPageRequestsLimitLighthouseTotal ?? autoLabel}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 500"
						label="Max. on-page requests per domain with Lighthouse"
					>
						<StaticText focusTarget="onPageRequestsLimitLighthousePerDomain">
							{adminSettings?.onPageRequestsLimitLighthousePerDomain ?? autoLabel}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 2097152 b (2 MB)"
						label="DOM size limit"
					>
						<StaticText focusTarget="domSizeLimit">
							{adminSettings?.domSizeLimit ?? autoLabel}

							{adminSettings?.domSizeLimit && ' b'}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 3000 ms (3 s)"
						label="DOM stabilization period"
					>
						<StaticText focusTarget="domStabilizationPeriod">
							{adminSettings?.domStabilizationPeriod ?? autoLabel}

							{adminSettings?.domStabilizationPeriod && ' ms'}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 15000 ms (15 s)"
						label="Lighthouse FCP Timeout"
					>
						<StaticText focusTarget="lighthouseFcpTimeout">
							{adminSettings?.lighthouseFcpTimeout ?? autoLabel}

							{adminSettings?.lighthouseFcpTimeout && ' ms'}
						</StaticText>
					</FormRow>

					<FormRow
						description="auto: 20971520 b (20MB)"
						label="Lighthouse Chromium resource cache size limit"
					>
						<StaticText focusTarget="lighthouseChromiumResourceCacheSizeLimit">
							{adminSettings?.lighthouseChromiumResourceCacheSizeLimit ?? autoLabel}

							{adminSettings?.lighthouseChromiumResourceCacheSizeLimit && ' b'}
						</StaticText>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{adminSettings !== null && (
					<Form
						defaultValues={{
							domSizeLimit: adminSettings.domSizeLimit,
							domStabilizationPeriod: adminSettings.domStabilizationPeriod,
							ipPools: adminSettings.ipPools,
							lighthouseChromiumResourceCacheSizeLimit: adminSettings.lighthouseChromiumResourceCacheSizeLimit,
							lighthouseFcpTimeout: adminSettings.lighthouseFcpTimeout,
							mainRequestSizeLimit: adminSettings.mainRequestSizeLimit,
							mainRequestTimeout: adminSettings.mainRequestTimeout,
							navigationTimeout: adminSettings.navigationTimeout,
							onPageRequestsLimitLighthouseTotal: adminSettings.onPageRequestsLimitLighthouseTotal,
							onPageRequestsLimitLighthousePerDomain: adminSettings.onPageRequestsLimitLighthousePerDomain,
							onPageRequestsLimitNormalTotal: adminSettings.onPageRequestsLimitNormalTotal,
							onPageRequestsLimitNormalPerDomain: adminSettings.onPageRequestsLimitNormalPerDomain,
						}}
						onSuccess={handleSubmit}
					>
						{({ values }) => (
							<>
								<FormRows>
									{!isDomTracked && !isLighthouseMonitored && (
										<FormRow
											htmlFor="ipPools"
											label="IP pools"
										>
											<MultiselectField
												isOnlyLinkVisible={true}
												name="ipPools"
												options={ipPoolsOptions}
											/>
										</FormRow>
									)}

									<WebsiteAdminFetchingSettingMainRequestSizeLimitFieldRow
										name="mainRequestSizeLimit"
									/>

									<WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow
										name="mainRequestTimeout"
									/>

									<WebsiteAdminFetchingSettingNavigationTimeoutFieldRow
										mainRequestTimeoutMs={values.mainRequestTimeout ?? 10000}
										name="navigationTimeout"
									/>

									<WebsiteAdminFetchingSettingOnPageRequestsLimitNormalTotalFieldRow
										name="onPageRequestsLimitNormalTotal"
									/>

									<WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow
										name="onPageRequestsLimitNormalPerDomain"
									/>

									<FormRow
										description="auto: 5000"
										htmlFor="onPageRequestsLimitLighthouseTotal"
										label="Max. on-page requests in total with Lighthouse"
									>
										<FieldStatus name="onPageRequestsLimitLighthouseTotal">
											<TextField
												attributes={{
													min: 0,
												}}
												name="onPageRequestsLimitLighthouseTotal"
												placeholder="auto"
												trimValue={true}
												type={TextFieldType.Number}
												width={100}
											/>
										</FieldStatus>
									</FormRow>

									<FormRow
										description="auto: 500"
										htmlFor="onPageRequestsLimitLighthousePerDomain"
										label="Max. on-page requests per domain with Lighthouse"
									>
										<FieldStatus name="onPageRequestsLimitLighthousePerDomain">
											<TextField
												attributes={{
													min: 0,
												}}
												name="onPageRequestsLimitLighthousePerDomain"
												placeholder="auto"
												trimValue={true}
												type={TextFieldType.Number}
											/>
										</FieldStatus>
									</FormRow>

									<WebsiteAdminFetchingSettingDomSizeLimitFieldRow
										name="domSizeLimit"
									/>

									<WebsiteAdminFetchingSettingDomStabilizationPeriodFieldRow
										name="domStabilizationPeriod"
									/>

									<FormRow
										description="auto: 15000 ms (15 s)"
										htmlFor="lighthouseFcpTimeout"
										label="Lighthouse FCP Timeout"
									>
										<FieldStatus name="lighthouseFcpTimeout">
											<FormFieldsDecorator suffix="ms">
												<TextField
													attributes={{
														min: 0,
													}}
													name="lighthouseFcpTimeout"
													placeholder="auto"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/>
											</FormFieldsDecorator>
										</FieldStatus>
									</FormRow>

									<FormRow
										description="auto: 20971520 b (20 MB)"
										htmlFor="lighthouseChromiumResourceCacheSizeLimit"
										label="Lighthouse Chromium resource cache size limit"
									>
										<FieldStatus name="lighthouseChromiumResourceCacheSizeLimit">
											<FormFieldsDecorator suffix="b">
												<TextField
													attributes={{
														min: 0,
													}}
													name="lighthouseChromiumResourceCacheSizeLimit"
													placeholder="auto"
													trimValue={true}
													type={TextFieldType.Number}
													width={100}
												/>
											</FormFieldsDecorator>
										</FieldStatus>
									</FormRow>
								</FormRows>

								<ButtonsLayout>
									<CancelButton />
									<SaveSubmitButton />
								</ButtonsLayout>
							</>
						)}
					</Form>
				)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteAdminFetchingSettingsForm;
