import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import ChangesChart from './ChangesChart';
import NoDataMessage from './NoDataMessage';
import Widget from './Widget';
import WidgetCell from '~/components/patterns/widgets/WidgetCell';

import {
	useIssuesChangesWidgetQuery,
} from './IssuesChangesWidget.gql';

import useDashboardDateRange from '~/hooks/useDashboardDateRange';
import useDashboardQuery from '~/hooks/useDashboardQuery';
import useDashboardScopeSection from '~/hooks/useDashboardScopeSection';
import useNavigateToHistoricalIssues from '~/hooks/useNavigateToHistoricalIssues';
import useNavigation from '~/hooks/useNavigation';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	WidgetType,
	formatDateRangeForQuery,
} from '~/model/dashboard';



const messages = defineMessages({
	primaryActionLink: {
		id: 'ui.dashboardIssueChangesWidget.primaryActionLink',
	},
	title: {
		id: 'ui.dashboardIssueChangesWidget.title',
	},
});

const messagesCategory = defineMessages({
	opened: {
		id: 'ui.issuesOverview.charts.historicalIssuesChart.legend.openedIssues',
	},
	resolved: {
		id: 'ui.issuesOverview.charts.historicalIssuesChart.legend.resolvedIssues',
	},
});



const CATEGORIES = [
	{
		name: 'opened',
		color: '#FF5959',
	},
	{
		name: 'resolved',
		color: '#42CC67',
	},
];



const IssuesChangesWidget: React.FC = () => {
	const intl = useIntl();
	const navigation = useNavigation();

	const dateRange = useDashboardDateRange();
	const navigateToHistoricalIssues = useNavigateToHistoricalIssues();
	const scopeSection = useDashboardScopeSection();
	const websiteId = useWebsiteId();

	const {
		data,
		loading,
		reasonForNoData,
	} = useDashboardQuery('issuesChanges', useIssuesChangesWidgetQuery, {
		variables: {
			scope: scopeSection.scope,
			websiteId,
			...formatDateRangeForQuery(dateRange),
		},
	});

	const categories = React.useMemo(
		() => {
			return CATEGORIES.map((category) => ({
				color: category.color,
				label: intl.formatMessage(messagesCategory[category.name]),
				name: category.name,
			}));
		},
		[
			intl,
		],
	);

	const chartData = React.useMemo(
		() => {
			if (data === null) {
				return null;
			}

			return data.map((item) => {
				const changes = {};

				CATEGORIES.forEach((category) => {
					const change = item.changes.find((change) => change.changeType === category.name);

					if (change !== undefined) {
						changes[category.name] = change.numberOfIssues;
					}
				});

				return {
					changes,
					endDate: item.endDate,
					startDate: item.startDate,
				};
			});
		},
		[
			data,
		],
	);

	const handleChartClick = React.useCallback(
		({ endDate, startDate }) => {
			navigation.navigate(
				navigateToHistoricalIssues({
					endDate,
					scope: scopeSection.scope,
					startDate,
				}),
			);
		},
		[
			navigateToHistoricalIssues,
			navigation,
			scopeSection.scope,
		],
	);

	return (
		<WidgetCell type={WidgetType.IssueChanges}>
			<Widget
				label={(
					<FormattedMessage {...messages.title} />
				)}
				loading={loading}
				overlay={chartData === null && (
					<NoDataMessage reasonForNoData={reasonForNoData} />
				)}
				primaryActionLink={{
					label: (
						<FormattedMessage {...messages.primaryActionLink} />
					),
					...navigateToHistoricalIssues({
						endDate: dateRange.end,
						scope: scopeSection.scope,
						startDate: dateRange.start,
					}),
				}}
			>
				{chartData !== null ? (
					<ChangesChart
						categories={categories}
						data={chartData}
						onBarClickCallback={handleChartClick}
					/>
				) : null}
			</Widget>
		</WidgetCell>
	);
};



export default IssuesChangesWidget;
