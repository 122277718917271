import React from 'react';

import GraphQL from '~/types/graphql';

import CoreWebVitalsAssessmentWidget from './CoreWebVitalsAssessmentWidget';
import CumulativeLayoutShiftWidget from './CumulativeLayoutShiftWidget';
import DistributionOfIsIndexableWidget from '~/components/app/Dashboard/DistributionOfIsIndexableWidget';
import DistributionOfPageTypeWidget from '~/components/app/Dashboard/DistributionOfPageTypeWidget';
import HealthChangesWidget from '~/components/app/Dashboard/HealthChangesWidget';
import HealthWidget from '~/components/app/Dashboard/HealthWidget';
import InteractionToNextPaintWidget from './InteractionToNextPaintWidget';
import IssuesChangesWidget from '~/components/app/Dashboard/IssuesChangesWidget';
import LargestContentfulPaintWidget from './LargestContentfulPaintWidget';
import ManuallyTrackedLoading from '~/components/app/ManuallyTrackedLoading';
import NumberOfActiveIncidentsWidget from '~/components/app/Dashboard/NumberOfActiveIncidentsWidget';
import NumberOfIssuesWidget from '~/components/app/Dashboard/NumberOfIssuesWidget';
import NumberOfPagesWidget from '~/components/app/Dashboard/NumberOfPagesWidget';
import PageChangesWidget from '~/components/app/Dashboard/PageChangesWidget';
import ScopeTitle from './ScopeTitle';
import WidgetGrid from '~/components/patterns/widgets/WidgetGrid';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';

import {
	type Scope,
} from '~/model/scopes';



type ScopeSectionContextType = {
	scope: Scope,
	suspendDataFetching: boolean,
};

const ScopeSectionContext = React.createContext<ScopeSectionContextType | null>(null);



type Props = {
	scope: Scope,
	suspendDataFetching?: boolean,
};

const ScopeSection: React.FC<Props> = (props) => {
	const {
		scope,
		suspendDataFetching = false,
	} = props;

	const accountId = useAccountId();
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);

	const isWebVitalsEnabled = accountEnabledFeatures !== null
		? accountEnabledFeatures.includes(GraphQL.AccountFeature.WebVitalsOriginSummary)
		: null;

	const scopeContext = React.useMemo(
		() => ({
			scope,
			suspendDataFetching,
		}),
		[
			scope,
			suspendDataFetching,
		],
	);

	const showWebVitals = scope === 'website' && isWebVitalsEnabled === true;

	return (
		<ScopeSectionContext.Provider value={scopeContext}>
			<WidgetGrid
				hasWebVitals={showWebVitals}
				title={(
					<ScopeTitle scope={scope} />
				)}
			>
				<HealthWidget />
				<HealthChangesWidget />
				<DistributionOfIsIndexableWidget />
				<DistributionOfPageTypeWidget />
				<ManuallyTrackedLoading defaultIsLoading={true}>
					<IssuesChangesWidget />
				</ManuallyTrackedLoading>
				<NumberOfActiveIncidentsWidget />
				<NumberOfIssuesWidget />
				<NumberOfPagesWidget />
				<ManuallyTrackedLoading defaultIsLoading={true}>
					<PageChangesWidget />
				</ManuallyTrackedLoading>

				{showWebVitals && (
					<>
						<CoreWebVitalsAssessmentWidget />
						<InteractionToNextPaintWidget />
						<LargestContentfulPaintWidget />
						<CumulativeLayoutShiftWidget />
					</>
				)}
			</WidgetGrid>
		</ScopeSectionContext.Provider>
	);
};



export default ScopeSection;

export {
	ScopeSectionContext,
};

