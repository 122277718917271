import {
	format,
	parse,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import DashboardHeader from '~/components/patterns/screens/dashboard/header/DashboardHeader';
import DateRangeFilter from '~/components/app/Dashboard/DateRangeFilter';
import HeaderTitleWithValue from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithValue';
import InternalLink from '~/components/patterns/links/InternalLink';
import LoadingMessage from '~/components/patterns/loaders/LoadingMessage';
import NoSearchResults, {
	NoSearchResultsContext,
} from '~/components/patterns/messages/embedded/NoSearchResults';
import ScopesList from './ScopesList';
import ScopeSelectionField, {
	type ScopeSelectionFieldRef,
} from './ScopeSelectionField';
import ScreenOverlay, {
	ScreenOverlayStyle,
} from '~/components/patterns/screens/parts/ScreenOverlay';
import ScrollableContentScreen from '~/components/logic/layouts/screens/ScrollableContentScreen';
import SendToGDSButton from '~/components/logic/dashboard/SendToGDSButton';

import useDashboardScopes from '~/hooks/useDashboardScopes';
import usePendoGuideInterfaceHint from '~/hooks/usePendoGuideInterfaceHint';
import useNavigation from '~/hooks/useNavigation';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteDisplayName from '~/hooks/useWebsiteDisplayName';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteTimeCreatedAt from '~/hooks/useWebsiteTimeCreatedAt';

import {
	type DateRange,
} from '~/model/dashboard';

import {
	getDefaultDateRange,
} from '~/model/dateRangePresets';

import {
	HINT_PENDO_GUIDE_DASHBOARD,
} from '~/model/interfaceHints';

import {
	PendoGuide,
} from '~/model/pendoGuides';



const messages = defineMessages({
	title: {
		id: 'ui.dashboard.title',
	},
	noScopesMessage: {
		id: 'ui.dashboard.noScopes.message',
	},
	noScopesSelect: {
		id: 'ui.dashboard.noScopes.select',
	},
});



const DashboardDateRangeContext = React.createContext<DateRange | null>(null);



const DashboardScreen: React.FC = () => {
	const navigation = useNavigation();
	const urlState = useUrlState();
	const areDateParametersInUrl = !!(urlState.params.startDate && urlState.params.endDate);
	const isGuideParameterInUrl = !!(urlState.params.showGuide);

	const scopes = useDashboardScopes();
	const websiteId = useWebsiteId();
	const websiteCreatedAt = useWebsiteTimeCreatedAt(websiteId);

	const displayName = useWebsiteDisplayName(websiteId);

	const scopeSelectionRef = React.useRef<ScopeSelectionFieldRef | null>(null);

	const dashboardPendoGuideInterfaceHint = usePendoGuideInterfaceHint({
		guide: PendoGuide.Dashboard,
		name: HINT_PENDO_GUIDE_DASHBOARD,
	});

	const [dateRange, setDateRange] = React.useState<DateRange>({
		end: urlState.params.endDate
			? parse(urlState.params.endDate, 'yyyy-MM-dd', new Date())
			: new Date(),
		start: urlState.params.startDate
			? parse(urlState.params.startDate, 'yyyy-MM-dd', new Date())
			: new Date(),
	});

	const updateDateRange = React.useCallback(
		(nextDateRange: DateRange) => {
			setDateRange(nextDateRange);

			navigation.navigate({
				options: {
					replace: !areDateParametersInUrl,
				},
				routeName: 'website.dashboard',
				routeParams: {
					endDate: format(nextDateRange.end, 'yyyy-MM-dd'),
					startDate: format(nextDateRange.start, 'yyyy-MM-dd'),
					websiteId,
				},
			});
		},
		[
			areDateParametersInUrl,
			navigation,
			setDateRange,
			websiteId,
		],
	);

	React.useEffect(
		() => {
			if (websiteCreatedAt !== null && !areDateParametersInUrl) {
				const defaultDateRange = getDefaultDateRange(websiteCreatedAt);

				updateDateRange({
					end: defaultDateRange.endDate(),
					start: defaultDateRange.startDate(websiteCreatedAt),
				});
			}
		},
		[
			areDateParametersInUrl,
			updateDateRange,
			websiteCreatedAt,
		],
	);

	React.useLayoutEffect(
		() => {
			if (!areDateParametersInUrl) {
				return;
			}

			setDateRange(
				(dateRange) => {
					if (
						format(dateRange.end, 'yyyy-MM-dd') === urlState.params.endDate
						&& format(dateRange.start, 'yyyy-MM-dd') === urlState.params.startDate
					) {
						return dateRange;
					}

					return {
						end: new Date(urlState.params.endDate),
						start: new Date(urlState.params.startDate),
					};
				},
			);
		},
		[
			areDateParametersInUrl,
			setDateRange,
			urlState,
		],
	);

	React.useEffect(
		() => {
			if (isGuideParameterInUrl) {
				dashboardPendoGuideInterfaceHint.showHint();

				navigation.navigate({
					routeParams: {
						...urlState.params,
						showGuide: undefined,
					},
				});
			}
		},
		[
			dashboardPendoGuideInterfaceHint,
			isGuideParameterInUrl,
			navigation,
			urlState,
		],
	);

	return (
		<DashboardDateRangeContext.Provider value={dateRange}>
			<ScrollableContentScreen
				contentOverlay={
					websiteCreatedAt === null ? (
						<ScreenOverlay>
							<LoadingMessage title="Preparing dashboard" />
						</ScreenOverlay>
					) : scopes?.length === 0 ? (
						<ScreenOverlay style={ScreenOverlayStyle.Light}>
							<NoSearchResults
								additionalInfo={(
									<FormattedMessage
										{...messages.noScopesSelect}
										values={{
											linkSelect: (chunks) => (
												<InternalLink
													onClickCallback={() => {
														scopeSelectionRef.current?.open();
													}}
												>
													{chunks}
												</InternalLink>
											),
										}}
									/>
								)}
								context={NoSearchResultsContext.Scope}
							>
								<FormattedMessage {...messages.noScopesMessage} />
							</NoSearchResults>
						</ScreenOverlay>
					) : null
				}
				header={(
					<DashboardHeader
						dateRangePicker={(
							websiteCreatedAt !== null ? (
								<DateRangeFilter
									minDate={websiteCreatedAt}
									onChangeCallback={updateDateRange}
								/>
							) : null
						)}
						otherButtons={(
							<SendToGDSButton />
						)}
						scopeSelection={(
							<ScopeSelectionField
								ref={scopeSelectionRef}
							/>
						)}
						title={(
							<HeaderTitleWithValue
								title={(
									<FormattedMessage {...messages.title} />
								)}
								value={displayName ?? ''}
							/>
						)}
					/>
				)}
				key={websiteId}
			>
				{scopes !== null ? (
					<ScopesList scopes={scopes} />
				) : null}
			</ScrollableContentScreen>
		</DashboardDateRangeContext.Provider>
	);
};



export default DashboardScreen;

export {
	DashboardDateRangeContext,
};
