import React from 'react';

import type CK from '~/types/contentking';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CookiesField from '~/components/app/CookiesField';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FetchingLocationField from '~/components/app/FetchingLocationField';
import FieldRowBuilder from '~/components/app/FieldRowBuilder';
import FieldRowBuilderDisplaySlot from '~/components/app/FieldRowBuilderDisplaySlot';
import FieldRowBuilderFieldSlot from '~/components/app/FieldRowBuilderFieldSlot';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HttpHeadersField from './HttpHeadersField';
import InternalLink from '~/components/patterns/links/InternalLink';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField from '~/components/atoms/forms/components/TextField';
import UrlBlocklistField from '~/components/app/UrlBlocklistField';
import WebsiteAdminFetchingSettingDomSizeLimitFieldRow from '~/components/app/WebsiteAdminFetchingSettingDomSizeLimitFieldRow';
import WebsiteAdminFetchingSettingDomStabilizationPeriodFieldRow from '~/components/app/WebsiteAdminFetchingSettingDomStabilizationPeriodFieldRow';
import WebsiteAdminFetchingSettingMainRequestSizeLimitFieldRow from '~/components/app/WebsiteAdminFetchingSettingMainRequestSizeLimitFieldRow';
import WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow from '~/components/app/WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow';
import WebsiteAdminFetchingSettingNavigationTimeoutFieldRow from '~/components/app/WebsiteAdminFetchingSettingNavigationTimeoutFieldRow';
import WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow from '~/components/app/WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow';
import WebsiteAdminFetchingSettingOnPageRequestsLimitNormalTotalFieldRow from '~/components/app/WebsiteAdminFetchingSettingOnPageRequestsLimitNormalTotalFieldRow';
import WebsiteAdminTestFetchViaRenderingCustomUserAgentFieldRow from '~/components/app/WebsiteAdminTestFetchViaRenderingCustomUserAgentFieldRow';
import WebsiteAdminTestFetchViaRenderingUserAgentFieldRow from '~/components/app/WebsiteAdminTestFetchViaRenderingUserAgentFieldRow';
import WebsiteOnPageRequestBlockingModeField from '~/components/app/WebsiteOnPageRequestBlockingModeField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useTestFetchViaRenderingLazyQuery,
	useWebsiteAdminTestFetchViaRenderingModalQuery,
} from '~/components/app/WebsiteAdminTestFetchViaRenderingModal.gql';

import useFormContext from '~/hooks/useFormContext';
import useOnPageRequestBlocking from '~/hooks/useOnPageRequestBlocking';
import useWebsiteHttpHeaders from '~/hooks/useWebsiteHttpHeaders';
import useWebsiteIsHttpAuthenticationEnabled from '~/hooks/useWebsiteIsHttpAuthenticationEnabled';

import {
	UserAgent,
} from '~/model/websites/userAgents';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminTestFetchViaRenderingModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const onPageRequestBlocking = useOnPageRequestBlocking(websiteId);

	const { data } = useWebsiteAdminTestFetchViaRenderingModalQuery({
		variables: {
			websiteId,
		},
	});

	const userAgentType = data?.website?.userAgentType ?? null;
	const userAgentValue = data?.website?.userAgentValue ?? null;
	const onPageRequestsWhitelistRules = data?.website?.adminSettings?.onPageRequestsWhitelistRules ?? null;
	const websiteHttpHeaders = useWebsiteHttpHeaders(websiteId);
	const websiteIsHttpAuthenticationEnabled = useWebsiteIsHttpAuthenticationEnabled(websiteId);

	const [testFetchViaRendering] = useTestFetchViaRenderingLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const [result, setResult] = React.useState<any>(null);
	const [changedSettings, setChangedSettings] = React.useState<ReadonlyArray<string>>([]);

	const handleSubmit = React.useCallback(
		async (values) => {
			const cookies = values.cookies?.filter(
				(cookie) => !!cookie.value,
			) ?? null;

			const httpHeaders = values.httpHeaders?.filter(
				(header) => !!header.name && !!header.value,
			) ?? null;

			const response = await testFetchViaRendering({
				variables: {
					settings: {
						cookies,
						domSizeLimit: values.domSizeLimit,
						domStabilizationPeriod: values.domStabilizationPeriod,
						fetchingLocation: values.fetchingLocation,
						httpHeaders,
						ipPool: null,
						isBlacklistDisabled: null,
						lighthouseChromiumResourceCacheSizeLimit: null,
						lighthouseFcpTimeout: null,
						mainRequestSizeLimit: values.mainRequestSizeLimit,
						mainRequestTimeout: values.mainRequestTimeout,
						navigationTimeout: values.navigationTimeout,
						onPageRequestsBlockingMode: values.onPageRequestsBlockingMode,
						onPageRequestsBlockingRules: values.onPageRequestsBlockingRules,
						onPageRequestsLimitLighthousePerDomain: null,
						onPageRequestsLimitLighthouseTotal: null,
						onPageRequestsLimitNormalPerDomain: values.onPageRequestsLimitNormalPerDomain,
						onPageRequestsLimitNormalTotal: values.onPageRequestsLimitNormalTotal,
						onPageRequestsWhitelistRules: values.onPageRequestsWhitelistRules,
						userAgentSettings: values.userAgent ? {
							type: values.userAgent,
							customValue: values.userAgentCustomValue,
						} : null,
					},
					url: values.url,
					websiteId,
				},
			});

			setResult(response);
		},
		[
			testFetchViaRendering,
			websiteId,
		],
	);

	const validations = React.useMemo(
		() => {
			const result = {
				validatePurgedProperties: validateField(
					'url',
					(f) => [
						f.validateNonEmpty(),
					],
				),
			};

			if (changedSettings.includes('fetchingLocation')) {
				result['fetchingLocation'] = validateField(
					'fetchingLocation',
					(f) => [
						f.validateNonEmpty(),
					],
				);
			}

			if (changedSettings.includes('userAgent')) {
				result['userAgent'] = validateField(
					'userAgent',
					(f) => ([
						f.validateNonEmpty(),
					]),
				);
				result['userAgentCustomValue'] = validateField(
					'userAgentCustomValue',
					(f) => ([
						f.whenOtherField('userAgent', ({ value }) => {
							return value === UserAgent.Custom;
						}),
						f.validateNonEmpty(),
						f.validateMaximumLength(2048),
					]),
				);
			}

			return result;
		},
		[
			changedSettings,
		],
	);

	const formattedResult: Record<string, any> = {};

	if (result?.data?.testFetchViaRendering) {
		if (result.data.testFetchViaRendering.response) {
			formattedResult.response = {
				statusCode: result.data.testFetchViaRendering.response.statusCode,
				httpHeaders: Object.fromEntries(result.data.testFetchViaRendering.response.httpHeaders.map(
					({ headerName, values }) => [headerName, values],
				)),
			};

			formattedResult.analyticsDetected = result.data.testFetchViaRendering.analyticsDetected;
			formattedResult.analyticsNotDetected = result.data.testFetchViaRendering.analyticsNotDetected;
		} else {
			formattedResult.failureReason = result.data.testFetchViaRendering.failureReason;
		}

		formattedResult.totalNumberOfInterceptedRequests = result.data.testFetchViaRendering.totalNumberOfInterceptedRequests;

		formattedResult.interceptedRequestsPerDomain = Object.fromEntries(
			result.data.testFetchViaRendering.interceptedRequestsPerDomain.map(
				({ domain, numberOfRequests }) => [domain, numberOfRequests],
			),
		);

		formattedResult.interceptedRequestsStuckInBrowser = result.data.testFetchViaRendering.interceptedRequestsStuckInBrowser.map(
			({ url }) => url,
		);

		formattedResult.blockedInterceptedRequests = result.data.testFetchViaRendering.blockedInterceptedRequests.map(
			({ reason, url }) => ({ url, reason }),
		);

		formattedResult.console = result.data.testFetchViaRendering.console;
	}

	return (
		<SimpleModal
			alignToTop={true}
			size={SimpleModalSize.XLarge}
			title="Test fetch via rendering"
		>
			<Form
				defaultFocus="url"
				defaultValues={{
					userAgent: userAgentType,
					userAgentCustomValue: userAgentValue,
					fetchingLocation: null,
					httpHeaders: websiteHttpHeaders,
					onPageRequestsBlockingRules: onPageRequestBlocking?.rules,
					onPageRequestsWhitelistRules,
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				{({ values }) => (
					<>
						<FormRows>
							<FormRow
								fullwidth={true}
								htmlFor="url"
								label="URL"
							>
								<FieldStatus
									name="url"
								>
									<TextField
										name="url"
										width="100%"
									/>
								</FieldStatus>
							</FormRow>

							<FieldRow
								changedSettings={changedSettings}
								defaultValue={userAgentType}
								name="userAgent"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminTestFetchViaRenderingUserAgentFieldRow
									changedSettings={changedSettings}
									userAgent={userAgentType as any}
								/>
							</FieldRow>

							<WebsiteAdminTestFetchViaRenderingCustomUserAgentFieldRow
								changedSettings={changedSettings}
								userAgent={userAgentType as any}
							/>

							<FieldRow
								changedSettings={changedSettings}
								name="fetchingLocation"
								setChangedSettings={setChangedSettings}
							>
								<FormRow
									htmlFor="fetchingLocation"
									label="Fetching location"
								>
									<FieldStatus
										name="fetchingLocation"
									>
										<FieldRowBuilderDisplaySlot />
										<FieldRowBuilderFieldSlot>
											<FetchingLocationField
												name="fetchingLocation"
											/>
										</FieldRowBuilderFieldSlot>
									</FieldStatus>
								</FormRow>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								defaultValue={websiteHttpHeaders}
								name="httpHeaders"
								setChangedSettings={setChangedSettings}
							>
								<FormRow
									fullwidth={true}
									htmlFor="httpHeaders"
									label="HTTP Headers"
								>
									<FieldStatus
										name="httpHeaders"
									>
										<FieldRowBuilderDisplaySlot />
										<FieldRowBuilderFieldSlot>
											<HttpHeadersField
												isHttpAuthenticationEnabled={websiteIsHttpAuthenticationEnabled ?? false}
												name="httpHeaders"
											/>
										</FieldRowBuilderFieldSlot>
									</FieldStatus>
								</FormRow>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="mainRequestSizeLimit"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingMainRequestSizeLimitFieldRow
									name="mainRequestSizeLimit"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="mainRequestTimeout"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingMainRequestTimeoutFieldRow
									name="mainRequestTimeout"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="navigationTimeout"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingNavigationTimeoutFieldRow
									mainRequestTimeoutMs={values.mainRequestTimeout ?? 10000}
									name="navigationTimeout"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="domSizeLimit"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingDomSizeLimitFieldRow
									name="domSizeLimit"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="domStabilizationPeriod"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingDomStabilizationPeriodFieldRow
									name="domStabilizationPeriod"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="onPageRequestsLimitNormalTotal"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingOnPageRequestsLimitNormalTotalFieldRow
									name="onPageRequestsLimitNormalTotal"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="onPageRequestsLimitNormalPerDomain"
								setChangedSettings={setChangedSettings}
							>
								<WebsiteAdminFetchingSettingOnPageRequestsLimitNormalPerDomainFieldRow
									name="onPageRequestsLimitNormalPerDomain"
								/>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								defaultValue={onPageRequestBlocking?.rules}
								name="onPageRequestsBlockingRules"
								setChangedSettings={setChangedSettings}
							>
								<FormRow
									fullwidth={true}
									htmlFor="onPageRequestsBlockingMode"
									label="On-page request blocking"
								>
									<FieldRowBuilderDisplaySlot />
									<FieldRowBuilderFieldSlot>
										<WebsiteOnPageRequestBlockingModeField
											__UGLY__transformEnumsToCamelCase={true}
											name="onPageRequestsBlockingMode"
										/>
									</FieldRowBuilderFieldSlot>
								</FormRow>

								<FormRow
									fullwidth={true}
									htmlFor="onPageRequestsBlockingRules"
									label=" "
								>
									<FieldRowBuilderFieldSlot>
										<UrlBlocklistField
											__UGLY__transformEnumsToCamelCase={true}
											name="onPageRequestsBlockingRules"
										/>
									</FieldRowBuilderFieldSlot>
								</FormRow>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								defaultValue={onPageRequestsWhitelistRules}
								name="onPageRequestsWhitelistRules"
								setChangedSettings={setChangedSettings}
							>
								<FormRow
									fullwidth={true}
									htmlFor="onPageRequestsWhitelistRules"
									label="On-page request whitelist rules"
								>
									<FieldRowBuilderDisplaySlot />
									<FieldRowBuilderFieldSlot>
										<UrlBlocklistField
											__UGLY__transformEnumsToCamelCase={true}
											name="onPageRequestsWhitelistRules"
										/>
									</FieldRowBuilderFieldSlot>
								</FormRow>
							</FieldRow>

							<FieldRow
								changedSettings={changedSettings}
								name="cookies"
								setChangedSettings={setChangedSettings}
							>
								<FormRow
									fullwidth={true}
									htmlFor="cookies"
									label="Cookies"
								>
									<FieldRowBuilderDisplaySlot />
									<FieldRowBuilderFieldSlot>
										<CookiesField
											name="cookies"
										/>
									</FieldRowBuilderFieldSlot>
								</FormRow>
							</FieldRow>
						</FormRows>

						<ButtonsLayout>
							<CancelButton />

							<SubmitButton
								allowNoChanges={true}
							>
								Test
							</SubmitButton>
						</ButtonsLayout>

						<pre>
							{JSON.stringify(formattedResult, null, ' ')}
						</pre>
					</>
				)}
			</Form>
		</SimpleModal>
	);
};



type FieldRowProps = {
	changedSettings: ReadonlyArray<string>,
	children: React.ReactNode,
	defaultValue?: any,
	name: string,
	setChangedSettings: (update: (changedSettings: ReadonlyArray<string>) => ReadonlyArray<string>) => void,
};

const FieldRow: React.FC<FieldRowProps> = (props) => {
	const {
		changedSettings,
		children,
		defaultValue = null,
		name,
		setChangedSettings,
	} = props;

	const formContext = useFormContext();

	const renderDisplay = React.useCallback(
		() => (
			<StaticText>
				<Emphasis>
					per website settings
				</Emphasis>
				{' '}
				<InternalLink
					onClickCallback={() => {
						formContext.setValues({ ...formContext.values, ...{ [name]: defaultValue } });
						setChangedSettings((changedSettings: ReadonlyArray<string>) => [...changedSettings, name]);
					}}
				>
					change
				</InternalLink>
			</StaticText>
		),
		[
			defaultValue,
			formContext,
			name,
			setChangedSettings,
		],
	);

	const renderField = React.useCallback(
		(field: React.ReactNode) => (
			<>
				{field}
				{' '}
				<InternalLink
					onClickCallback={() => {
						setChangedSettings((changedSettings: ReadonlyArray<string>) => changedSettings.filter((item) => item !== name));
						formContext.setValues({ ...formContext.values, ...{ [name]: null } });
					}}
				>
					reset
				</InternalLink>
			</>
		),
		[
			name,
			formContext,
			setChangedSettings,
		],
	);

	const isEditable = changedSettings.includes(name);

	return (
		<FieldRowBuilder
			isEditable={isEditable}
			renderDisplay={renderDisplay}
			renderField={renderField}
		>
			<>{children}</>
		</FieldRowBuilder>
	);
};



export default WebsiteAdminTestFetchViaRenderingModal;
